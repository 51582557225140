<template>
<el-row class="footer" >
      <el-col  class="footer-box">
      <el-row style="display:flex;">
        <el-col :span="4"></el-col>
        <el-col class="footer-con" :span="8">
          <el-col>中债通 (c) 2009-2022 版权所有</el-col>
          <el-col>咨询电话：010-51058067</el-col>
          <el-col :xs="22" :md="22" :lg="22">
            <el-row  class="menu_list">
              <el-col :xs="4" :md="4" :lg="4" class="con-item">
               <router-link to="/Business" class="item">免费注册 </router-link> 
                </el-col>
              <el-col :xs="4" :md="4" :lg="4" class="con-item">
               <router-link to="/Business" class="item">实名认证 </router-link> 
                </el-col>
                <el-col :xs="4" :md="4" :lg="4" class="con-item">
               <router-link to="/Operation" class="item">帮助中心 </router-link> 
                </el-col>
              <el-col :xs="4" :md="4" :lg="4" class="con-item">
               <router-link to="/Business" class="item">质押管理 </router-link> 
                </el-col>
              <el-col :xs="4" :md="4" :lg="4" class="con-item">
               <router-link to="/Business" class="item">债权转让 </router-link>
                </el-col>
              
              <el-col :xs="4" :md="4" :lg="4" class="con-item">
              <router-link to="/About" style=" border:none">关于我们</router-link>  
                </el-col>
            </el-row>
          </el-col>
          <el-col style="    display: flex;
    align-items: center;">
            <img src="../assets/img/beianIcon.png" style="padding-right: 10px;"  />
            <a class="beian" href="https://beian.miit.gov.cn" target="view_window">京ICP备2020039783号-2</a>
            </el-col>
        </el-col>
        <el-col :span="12" class="QR">
          <div class="QR-item">
            
               <span style="padding-bottom: 10px; cursor: pointer;"  @click="goDownload('#Android')">手机客户端</span>
               <el-col>
                  <img src="../assets/img/foot_01_h.png"  style="width: 20px;height: 20px;cursor: pointer;" @click="goDownload('#Android')">
                  <img src="../assets/img/foot_02_h.png"  style="width: 20px;height: 20px;padding: 0 15px;cursor: pointer;" @click="goDownload('#IOS')">
               </el-col>
                <span style="padding:10px 0; cursor: pointer;" @click="goDownload('#WX')">微信小程序</span>
                <el-col>
                  <img src="../assets/img/foot_03_h.png" style="width: 20px;height: 20px;float: left;cursor: pointer;" @click="goDownload('#WX')">
                </el-col>
          </div>
          <div class="QR-item">
            <img src="../assets/img/10.png" />
            <span>Android下载</span>
          </div>
          <div class="QR-item">
            <img src="../assets/img/10.png" />
            <span>IOS下载</span>
          </div>
          
        </el-col>
      </el-row>
    </el-col>
    </el-row>

</template>
<script>
export default {
    data(){
        return{}
    },
    methods:{
      goDownload(id){
        if(this.$route.path=='/Download'){
           document.querySelector(id).scrollIntoView(true);
        }else{
           this.$router.push({name:'Download',path:'/Download',params:{
           id:id
        }})
        }
      }
    }
}
</script>
<style lang="less" scoped>
.footer {
  background: #0252aa;
  height: 200px;
  min-width: 1200px;
  // position: absolute;
  // bottom: 0;
  width: 100%;

  color: #fff;
  font-size: 14px;
  text-align: center;
  .footer-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
     .footer-con {
    text-align: left;
    line-height: 2;
    .con-item{
      white-space: nowrap;
      // border-right: 1px solid #fff;
    a{
        color: #ffff;
    border: none;
    text-decoration: none;
      border-right: 1px solid #fff;
    padding-right: 5px;
    }
    }
    .con-item :hover{
      background: #fff;
      color: #0252aa;
    }
   
  }
  }
 
}
.QR{
  display: flex;
  img{
    width: 138px;
  height: 138px;
  }
  .QR-item{
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 14px;
    margin: 0 10px;
  }
  
}
.beian{
  color: #fff;
}
</style>