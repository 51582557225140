import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import Index from '../views/Index.vue'
// 债权流转
import Circulation from '../views/Circulation.vue'
// 债权质押
import Pledge from '../views/Pledge.vue'
// 债权抵消
import offset from '../views/Offset.vue'
// 业务平台
import Business from '../views/Business.vue'
// 操作指南
import Operation from '../views/Operation.vue'
// 关于我们
import About from '../views/About.vue'
// 个人和企业登录
import PersonalLogin from '../views/Personal_login.vue'
// 下载页
import Download from '../views/Download.vue'
// 应收账款确认公司专区
import CollectLogin from '../views/Collect_login.vue'
// 应收账款支付公司专区
import PayLogin from '../views/Pay_login.vue'
//金融机构
import FinancialLogin from '../views/Financial_login.vue'
// 政府监管机构
import GovernmentLogin from '../views/Government_login.vue'
// 合作机构
import CooperationLogin from '../views/Cooperation_login.vue'
// 政策法规
import Article from '../views/Article.vue'
// 文章详情页
import ArticleDetail from '../views/Article_detail.vue'
Vue.use(VueRouter)
const routes = [
  // {
  //   path: '/',
  //   redirect: '/Business',
  //   meta: { title:"中债通-业务平台"},
  // },
  {
    path: '/',
    redirect: '/Index',
    meta: { title:"中债通-首页"},
  },
  {
    path: '/Index',
    component:Index,
    meta: { title:"中债通-首页"}
  },
  {
    path:'/Circulation',
    component:Circulation,
    meta:{title:"中债通-债权流转"}
  },{
    path:'/Pledge',
    component:Pledge,
    meta:{title:"中债通-债权质押"}
  },{
    path:'/offset',
    component:offset,
    meta:{title:"中债通-债权抵消"}
  },
  {
    path:'/Business',
    component:Business,
    meta:{title:"中债通-业务平台"},
    
  },
  {
    path:'/Operation',
    component:Operation,
    meta:{title:"中债通-帮助中心"}
  },{
    path:'/About',
    component:About,
    name:'About',
    meta:{title:"中债通-关于我们"}
  },
  {
    path:'/PersonalLogin',
    component:PersonalLogin,
    meta:{title:"中债通-个人和企业登录"}
  },{
    path:'/Download',
    component:Download,
    name:'Download',
    meta:{title:'中债通-下载中心'}
  },
  {
    path:'/CollectLogin',
    component:CollectLogin,
    meta:{title:'中债通-应收账款公司专区'}
  },{
    path:'/PayLogin',
    component:PayLogin,
    meta:{title:'中债通-应收账款支付公司专区'}
  },{
    path:'/FinancialLogin',
    component:FinancialLogin,
    meta:{title:'中债通-金融机构专区'}
  },{
    path:'/GovernmentLogin',
    component:GovernmentLogin,
    meta:{title:'中债通-政府监管机构专区'}
  },{
    path:'/CooperationLogin',
    component:CooperationLogin,
    meta:{title:'中债通-合作机构专区'}
  },{
    path:'/Article',
    component:Article,
    name:'Article',
    meta:{title:'中债通-政策法规'}
  },{
    path:'/ArticleDetail',
    component:ArticleDetail,
    name:'ArticleDetail',
    meta:{title:'中债通-政策法规'}
  }
]

const router = new VueRouter({
  routes
})

export default router
