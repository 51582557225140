<template>
  <div>
    <el-row>
      <el-col>
        <img src="../assets/img/87.jpg" style="width:100%;" />
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="content_view">
      <el-col class="content_box">
        <el-col class="navigation">
          <el-row class="navigation">
          <router-link to="/Index"><span>首页</span></router-link>  
            <span>></span>
           <router-link to="/Article"><span style="color:#004DA1;" >相关政策法规</span></router-link> 
          </el-row>
        </el-col>
        <el-col class="TextBox" >
         <el-row class="title">
            <span>{{title}}</span>
          </el-row>
          <el-row class="time">
            <span>{{release_time}}</span>
          </el-row>
           <el-row class="text_contentText" style="border-collapse: collapse;" v-html="content">
              <!-- <div>经济运行保持在合理区间，各年度视情提出经济增长预期目标，全员劳动生产率增长高于国内生产总值增长，城镇调查失业率控制在5.5%以内，物价水平保持总体平稳。</div> -->
          </el-row>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {ArticleDetail,improtantDetail} from "../api/article"
export default {
  data(){
    return{
      id:'',
      content:'',
      title:'',
      created_at:'',
      ImprotantID:''
    }
  },
  mounted(){
   this.id=this.$route.params.id
   this.ImprotantID=this.$route.params.ID
   this.get_detail()
   this.get_improtantArticle()
  },

  methods:{
 
   

//  获取文章详情
get_detail(){
  var data={
    id:this.id
  }
  ArticleDetail(data).then(res=>{
   this.content=res.data.data.content
   this.title=res.data.data.title
   this.release_time=res.data.data.release_time
  })
},
// 获取重要文章详情
 get_improtantArticle(){
   var data={
     id:this.ImprotantID
   }
   improtantDetail(data).then(res=>{
       this.content=res.data.data.content
   this.title=res.data.data.title
   this.release_time=res.data.data.release_time
   })
 }
  }
};
</script>
<style lang="less"  >
.navigation{
a {
  text-decoration: none;
  color: #004da1;
    
}
}

.content_view {
  
  flex-flow: column;
  align-items: center;
  .content_box {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 35px;
    .navigation {
      text-align: right;
      font-size: 14px;
      padding: 10px 0;
      color: #444444;
      span {
        padding: 0 0 0 5px;
      }
    }
    .TextBox{
         font-family: 宋体;
         color: #444444;
        .title{
         font-size: 32px;
    font-family: Microsoft Yahei;
    text-align: center;
    color: #333333;
      }
      .time{
          text-align: center;
          font-weight: 500;
          padding: 10px 0;
      }
      .small_title{ 
          text-align: center;
          font-weight: 600;
          padding: 10px 0;
      }
     
    }
  }
}
.text_contentTitle{
         color: #444444; 
         font-size: 12pt;
         padding: 5px 0;
      }
 .text_contentText{
    border-collapse: collapse;
    //  color: #444444;
    //  font-size: 12pt;
    //  text-indent:2em;
    //  line-height: 1.5;
 }  
 table{
   border-collapse: collapse;
 }
</style>