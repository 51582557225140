<template>
  <div >
    <el-row>
      <el-col>
        <img src="../assets/img/38.png" style="width:100%;" />
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="stystem">
      <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>债务链抵消系统简介</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row class="abstract" type="flex" justify="center">
      <el-col>
        <span>
          区内设立应收账款确认公司和应收账款支付公司，前者专门从事于应收账款的确权、开证工作，后者统一代区政府对上述债务进行终审核、甄别和到期支付。
          其他国有企业和私人主体也可在应收账款债权凭证流转系统登记对其他主体的应收账款，系统识别存在三角、多角债务时，
          可发现可抵消的债务金额和涉及主体，制定优化抵消方案，进行债务抵消。
        </span>
      </el-col>
    </el-row>
    <el-row class="guide" type="flex" >
        <el-col  :lg="20" :xl="16" class="guide-item">
        <span>操作指引</span>  
          </el-col>
    </el-row>
    <el-row type="flex" class="guide-detail" align="center" justify="center" style="padding:20px 0;">
        <el-col :xs="21" :md="21" :lg="20" :xl="20" :push="1">
            <img src="../assets/img/39.png" style="margin-left: 20px;">
        </el-col>
    </el-row>
    <el-row class="guide-detail">
        <el-col :xs="3" :md="3" :lg="3" :xl="3" class="left-box">
        <el-col :span="20">
           <router-link to="/Download"> 
          <div class="item-box">
           <div class="img"></div>
            <span>APP下载</span>
          </div>
           </router-link>
        </el-col>
        <el-col :span="8">
           
          <div class="item-box1" @click="goDownload('#WX')">
           <div class="img1"></div>
            <span>微信小程序</span>
          </div>
          
        </el-col>
        <el-col :span="8">
          <div class="item-box2" @click="call_me('#call')">
           <div class="img2"></div>
            <span>联系我们</span>
          </div>
        </el-col>
      </el-col>
        <el-col :lg="20" :xl="16" class="zy" >
            <el-row>
                <el-col class="guide-title">
                   <span>1.会员注册</span> 
                    </el-col>
                <el-col class="guide-con">
                  <span>各类市场主体登录中债通网或客户端（手机APP、微信小程序）免费注册成为任一国有独资的债权管理公司会员</span>  
                    </el-col>
            </el-row>
            <el-row>
                <el-col class="guide-title">
                   <span>2.上传债权债务信息</span> 
                    </el-col>
                <el-col class="guide-con">
                   <span>会员通过中债通网或客户端（手机APP、微信小程序）登录，上传应收账款信息。</span> 
                </el-col>
            </el-row>
            <el-row>
                <el-col class="guide-title">
                   <span>3.系统识别债务链</span> 
                    </el-col>
                <el-col class="guide-con">
                   <span>系统识别闭环或开环的债权债务关系链，发现可抵消的债务金额和涉及主体，制定优化抵消方案。系统通知债务链上各债务人确认债务，提示其在债务链上确认完成后可以进行抵消。</span> 
                </el-col>
            </el-row>
             <el-row>
                <el-col class="guide-title">
                   <span>4.债务人确认债务信息</span> 
                    </el-col>
                <el-col class="guide-con">
                   <span>债务链上各债务人在中债通网或客户端（手机APP、微信小程序）进行债务确认。</span> 
                </el-col>
            </el-row>
            <el-row>
                <el-col class="guide-title">
                   <span>5.债务链抵消或多元解纷</span> 
                    </el-col>
                <el-col class="guide-con">
                   <span>如债权确认完毕，形成闭环或开环债务链，系统发送债务抵消电子合同，签署后各主体债权债务自动抵消，并进行登记公示；若未能抵消（部分债务人未确认或不愿签署合同），则经债权人申请，可进行线下协商和抵消。</span> 
                </el-col>
            </el-row>
            <el-row>
                <el-col class="guide-title">
                   <span>6.查询、打印凭证（合同）</span> 
                    </el-col>
                <el-col class="guide-con">
                   <span>各涉及会员主体可根据账务、司法处理需要，登录客户端查询、下载打印以闭环抵消合同为基础，载明与其有直接债权债务关系的三方抵消情况并附有二维码、电子公章等信息的应收账款债权凭证（合同）。</span> 
                </el-col>
            </el-row>
        </el-col>
        <el-col :span="4"></el-col>
    </el-row>
    <el-row class="guide" type="flex" >
        <el-col :xs="16" :lg="20" :xl="16" class="guide-item">
          <span>债务链抵销流程图</span>
        </el-col>
    </el-row>
    <el-row type="flex" class="guide-detail" justify="center" style="padding-bottom: 20px;">
        <el-col :xs="20" :md="20" :lg="20" :xl="20"  class="lzt" :push="1">
            <img src="../assets/img/40.png" style="margin-left: 10px;">
        </el-col>
    </el-row>
    <el-row type="flex"  justify="center">
      <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>多元解纷</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>   
    <el-row class="guide" type="flex" >
        <el-col  :lg="20" :xl="16" class="guide-item">
          <span>诉前调解</span>
        </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="guide-detail" style="padding-bottom: 20px;">
        <el-col :xs="20" :md="20" :lg="20" :push="1" class="lzt">
            <img src="../assets/img/41.png" >
        </el-col>
    </el-row>
    <el-row class="mediation" type="flex" justify="center">
        <el-col >
            <el-row class="mediation-item">
            <el-col>
            <span>1.当事人自行和解的，可自行免费注册会员，将债权债务录入系统，参与抵销，化解矛盾纠纷。</span>  
        </el-col>
        </el-row>
        <el-row class="mediation-item">
            <el-col>
            <span>2.当事人经调解组织调解达成调解协议的，可申请调解组织或当事人自行将确定的债权债务录入系统，参与抵销，化解矛盾纠纷。</span>  
        </el-col>
        </el-row>
        <el-row class="mediation-item">
            <el-col>
            <span>3.当事人达成调解协议并依法申请司法确认的，人民法院出具确认调解协议效力的民事裁定书后，可申请调解组织或当事人自行将债权债务录入系统，参与抵销，也可申请调解组织开具纸质或电子应收账款债权凭证（合同）进行流转，化解矛盾纠纷。</span>  
        </el-col>
        </el-row>
        </el-col>
    </el-row>
    <el-row class="guide" type="flex" >
        <el-col :lg="20" :xl="16" class="guide-item">
          <span>诉中调解</span>
        </el-col>
    </el-row>
    <el-row  type="flex" justify="center" class="guide-detail" style="padding-bottom: 20px;">
        <el-col :xs="20" :md="20" :lg="20" :push="1" class="lzt">
            <img src="../assets/img/42.png" >
        </el-col>
    </el-row>
    <el-row class="mediation" type="flex" >
        <el-col >
            <el-row class="mediation-item">
            <el-col>
            <span>1.诉讼中的当事人自行和解的，可自行免费注册会员，将债权债务录入系统，参与抵销，化解矛盾纠纷。</span>  
        </el-col>
        </el-row>
        <el-row class="mediation-item">
            <el-col>
            <span> 2.诉讼中的当事人经调解达成协议，并经人民法院出具调解书后，可申请调解组织或当事人自行将确定的债权债务录入系统，参与抵销，也可申请调解组织开具纸质或电子应收账款债权凭证（合同）进行流转，化解矛盾纠纷。</span>  
        </el-col>
        </el-row>
        </el-col>
    </el-row>
    <el-row class="guide" type="flex" >
        <el-col  :lg="20" :xl="16" class="guide-item">
          <span>执行和解</span>
        </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="guide-detail" style="padding-bottom: 20px;">
        <el-col :xs="20" :md="20" :lg="20" :push="1" class="lzt" style="margin-top: 20px;">
            <img src="../assets/img/43.png" >
        </el-col>
    </el-row>
    <el-row class="mediation" type="flex" >
        <el-col>
            <el-row class="mediation-item">
            <el-col>
            <span>1.判决书已生效，执行中的当事人达成和解的，可申请调解组织或当事人自行将确定的债权债务录入系统，参与抵销，也可申请调解组织开具纸质或电子应收账款债权凭证（合同）进行流转，化解矛盾纠纷。</span>  
        </el-col>
        </el-row>
        <el-row class="mediation-item">
            <el-col>
            <span> 2.应收账款债权凭证（合同）记载的债权到期未能实现的部分，当事人可申请恢复强制执行，也可协商一致后重新开具凭证（合同）流转、抵付（销）。</span>  
        </el-col>
        </el-row>
        </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data(){
    return{}
  },
  methods:{
    call_me(id){
        this.$router.push({name:'About',path:'/About',params:{
           id:id
        }})
     },
     goDownload(id){
        if(this.$route.path=='/Download'){
           document.querySelector(id).scrollIntoView(true);
        }else{
           this.$router.push({name:'Download',path:'/Download',params:{
           id:id
        }})
        }
      }
  }
};
</script>
<style lang="less" scoped>
.stystem{
  width: 1200px;
  margin: 0 auto;
}
.con-title {
 display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
        width: 1200px;
  span {
    justify-content: center;
    display: flex;
    color: #004da1;
    font-size: 26px;
  }
  img{
     padding: 0 40px;
   // margin: 0 40px;
  }
}
.abstract {
  width: 1200px;
    margin: 0 auto;
  line-height: 25px;
  font-size: 16px;
  color: #202020;
  text-align: justify;
  text-indent: 2rem;
}
.guide{
    width: 1200px;
    margin: 0 auto;
    color: #004DA1;
    font-size: 24px;
    font-weight: bold;
    .guide-item{
      padding: 10px 0;
      span{
        border-bottom: 5px solid rgb(0, 77, 161);
        padding: 15px 0px;
      }
    }
    .heng{
        width: 95px;
        height: 6px;
        background: #004DA1;
    }
}
.guide-detail{
    display: flex;
    width: 1200px;
    margin:  0 auto;
    .guide-title{
        color: #FFB300;
        font-size: 18px;
        font-weight: bold;
        padding: 5px 0;
    }
    .guide-con{
        padding: 10px 0;
        font-size: 16px;
        color: #202020;
    }
}
.mediation{
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    width: 1200px;
    margin: 0 auto;
    .mediation-item{
        padding: 2px 0;
        color: #202020;
        font-size: 16px;
    }
}
.left-box {
  display: flex;
  flex-flow: column;
  .item-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 .img {
    background: url("../assets/img/15.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
  }
 
}
.item-box:hover{
    .img{
    background: url("../assets/img/15-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
 .left-box {
  display: flex;
  flex-flow: column;
  .item-box1 {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 .img1 {
    background: url("../assets/img/13.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
  }
 
}
.item-box1:hover{
    .img1{
    background: url("../assets/img/13-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
 .left-box {
  display: flex;
  flex-flow: column;
  .item-box2 {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 .img2 {
    background: url("../assets/img/14.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
    color: #333;
  }
 
}
.item-box2:hover{
    .img2{
    background: url("../assets/img/14-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
.zy{
  margin-left: 30px;
}
@media screen and (min-width: 1400px) {
  .zy{
    margin-left: 20px;
  }
}
</style>