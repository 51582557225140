<template>
  <div>
    <el-row>
      <el-col>
        <img src="../assets/img/55.png" style="width:100%;" />
      </el-col>
    </el-row>
    <el-row class="conpany" type="flex" justify="center">
      <!-- <img src="../assets/img/59.png" style="height: 350px;" /> -->
       <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>公司介绍</span>
        <img src="../assets/img/right.png">
      </el-col>
      <el-col class="conpany-box">
        <el-col :lg="11" :xl="10" style="margin-right: 30px;">
          <img src="../assets/img/56.png" style="width:100%;height: 330px;" />
        </el-col>
        <el-col :lg="11" :xl="11" class="conpany-con">
          <span>
            中债通业务系统是由北京聚通天下投资顾问有限公司下属中债通公司所创建的一整套应收账款债权登记和流转系统。
            北京聚通天下投资顾问有限公司成立于2009年，10多年来专注政府应收账款化债系统工程，包括债权的承接、确认、登记、流转、抵付、置换等一系列业务。中债通业务系统在结合10多年实践经验的基础上，
            运用最新大数据和区块链技术，在保障债权的真实性、有效性、合规性、唯一性、安全性的基础之上，开展债权流转业务，
            为地方政府债务化解提供了全新的解决思路，具有重大的社会意义和历史价值。
          </span>
        </el-col>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col class="system">
        <img src="../assets/img/57.png" style="width:100%;height:400px" />
        <div class="concept-title">
          <img src="../assets/img/left-1.png">
        <span>系统简介</span>
        <img src="../assets/img/right-1.png">
        </div>
        <el-row class="system-con">
          <el-col >
            <span>
              中债通业务系统的定位：以缓解国有企业及政府性债务短期偿还压力、
              进一步推动供应链金融的发展、增加区域经济活力为目标，
              为区域开展应收账款债权流转提供系统支持服务。
            </span>
          </el-col>
          <el-col>
            <span>
              应收账款债权凭证流转业务是指，由区（县）政府独资组建的应收账款债权管理公司
              ，将各经济主体拥有的政府、国企等产业链核心龙头企业承诺到期付款的应收账款，
              转变成纸质或电子的应收账款债权凭证，
              凭证记载的债权人可在银行等金融机构融资或者通过转让、融货、抵付、交易、质押等方式流转的专门业务。
            </span>
          </el-col>
          <el-col >
            <span>
              应收账款债权（纸质和电子）凭证摘要记载应收账款债权及转让、
              质押等相关信息，是应收账款转让、质押的辅证材料之一，不是有价证券。
            </span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>业务价值</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row class="cost" type="flex" justify="center">
      <el-col class="cost-con" :lg="12" :xl="12">
        <span>
          （一）推进应收账款流转业务，是坚持以习近平新时代中国特色社会主义思想为指导，
          全面贯彻党的十九大和十九届二中、三中、四中、五中全会精神，
          深入贯彻习近平总书记考察贵州重要讲话精神和习近平总书记在民营企业座谈会重要讲话精神，
          全面贯彻落实中央、省委、市委、区委关于防控金融风险的重要部署的一项举措，
          贯彻了李克强总理关于切实解决政府部门和国有企业拖欠民营企业账款问题的重要指示。
        </span>
        <p>
            （二）流转系统符合《国务院办公厅关于推广支持创新相关改革举措的通知》（国办发〔2017〕80号）、
          中国人民银行等八部委《关于金融支持工业稳增长调结构增效益的若干意见》（银发〔2016〕42号）、
          央行会同工信部、司法部、商务部、国资委、市场监管总局、银保监会、外汇局《关于规范发展供应链金融支持供应链产业链稳定循环和优化升级的意见》（2020年9月）等文件精神。
        </p>
      </el-col>
      <el-col :lg="12" :xl="12">
        <img src="../assets/img/58.png" />
      </el-col>
    </el-row>
    <el-row class="cost-con-item"  type="flex" justify="center">
      <el-col :lg="19" :xl="19" >
        <span>（三）流转系统对于解决政府和国企债务拖欠问题、解决企业融资难，降低流通成本、减少债务纠纷，服务地方经济健康有序发展有积极的推动作用和现实意义。</span>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>联系我们</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row class="cost" type="flex" >
      <el-col  style="font-size:18px;">
        <span>
          具体业务办理，请您联系就近的债权管理服务机构咨询办理，您可以通过中债通小程序或者APP-我的-债权管理服务机构获取具体联系信息。
        </span>
      </el-col>
      
    </el-row  >
    <el-row id="call" class="cost call_me"  type="flex" justify="center" >
      <el-col :lg="19" :xl="19" >
        <p class="call_title">业务合作及运营支持</p>
        <p>联系电话：010-51058067</p>
        <p>中债通客户端下载：<router-link to="/Download" class="click">下载</router-link>（含 Android版、IOS版、微信小程序版）</p>
      </el-col>
      <el-col>
       <img />
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data(){
    return{}
  },
  mounted(){
    var Id=this.$route.params.id
    console.log(Id)
    if(Id!=undefined){
      document.querySelector(Id).scrollIntoView(true);
    } 
  }
};
</script>
<style lang="less" scoped>
.con-title {
 display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
        width: 1200px;
            margin: 0 auto;
  span {
    justify-content: center;
    display: flex;
    color: #004da1;
    font-size: 26px;
  }
  img{
     padding: 0 40px;
   // margin: 0 40px;
  }
}
.conpany {
  display: flex;
  flex-flow: column;
  background-image: url('../assets/img/59.png');
      // background-position: -100px -30px;
      background-size: 100%;
    
  .conpany-box {
        display: flex;
    /* align-items: center; */
    justify-content: center;
      width: 1200px;
    margin: 0 auto;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 1100;
    // display: flex;
    // justify-content: center;
  }
  .conpany-con {
    line-height: 2;
    color: #202020;
    margin-left: 20px;
    font-size: 18px;
    text-align: justify;
  }
}
.system {
      padding: 20px 0 10px 0;
   .concept-title {
    position: absolute;
    left: 50%;
    top: 30px;
    z-index: 1100;
    transform: translate(-50%, 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
    span {
      font-size: 26px;
      color: #fff;
    }
    img{
      padding: 0 40px;
    }
  }
  .system-con {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 1200px;
    text-align: justify;
        display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 1100;
    .el-col {
      padding: 5px 0;
      span {
        line-height: 1.8;
    font-size: 16px;
      }
    }
  }
}
.cost {
  width: 1200px;
  margin: 0 auto;
  .cost-con {
    line-height: 1.8;
    font-size: 16px;
    text-align: justify;
    text-indent: 2rem;
    margin-right: 20px;
  }
  
}
.cost-con-item{
      line-height: 1.5;
      font-size:16px;
      padding: 10px 0;
    text-indent: 2rem;
    text-align: justify;
        margin: 0 20px;
        .el-col{
              width: 1200px;
    margin: 0 auto;
        }
  }
  .call_me{
    font-size: 18px;
     .click{
    color: #004da1;
  }
  .call_title{
    font-weight: 600;
    color: #004da1;
    
  }
  }
 
</style>