import { request } from '../utils/request'
// 首页文章轮播图及文章
export function ArticleList(data) {
    return request({
        url: 'getArticleAllIndex',
        method: 'post',
        data
    })
}
// 获取顶部或底部轮播图
export function Swiper(data){
    return request({
        url: 'getCarouselIndex',
        method: 'post',
        data
    })
}
// 获取文章详情
export function ArticleDetail(data){
    return request({
        url:'getArticleDetail',
        method:'post',
        data
    })
}
// 获取公告
export function Notice(data){
    return request({
        url:'getNoticeAllIndex',
        method:'post',
        data
    })
}
// 获取重要文章
export function ImprotantArticle(data){
    return request({
        url:'getArticleAllIndex1',
        method:'post',
        data
    })
}
// 获取重要文章详情
export function improtantDetail(data){
    return request({
        url:'getArticleDetail1',
        method:'post',
        data
    })
}