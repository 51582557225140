<template>
  <div class="aaa">
    <el-row class="menu" type="flex" align="middle">
      <el-col :lg="8" :xl="8" class="logo" >
        <img src="../assets/img/LOGO.png" @click="goIndex" />
      </el-col>
      <el-col :md="18" :lg="16" :xl="16">
        <el-row >
          <search></search>
        </el-row>
        <el-row type="flex" justify="end">
        <router-link to="/Index">
          <el-col  class="menu-item" >
            <img src="../assets/img/shouye icon.png" />
            <div>首页</div>
          </el-col>
        </router-link>
         <!-- <router-link to="/Business">
          <el-col  class="menu-item">
            <img src="../assets/img/yewu.png" />
            <div>业务平台</div>
          </el-col>
        </router-link> -->
        <router-link to="/Circulation">
          <el-col  class="menu-item">
            <img src="../assets/img/liuzhuan.png" />
            <div>债权流转</div>
          </el-col>
        </router-link>
        <router-link to="/Pledge">
          <el-col  class="menu-item">
            <img src="../assets/img/zhiya.png" />
            <div>债权质押</div>
          </el-col>
        </router-link>
        <router-link to="/Offset">
          <el-col  class="menu-item">
            <img src="../assets/img/dixiao.png" />
            <div>债务抵消</div>
          </el-col>
        </router-link>
        <a  href="http://www.zhaitongyun.com" target="view_window">
          <el-col  class="menu-item">
            <img src="../assets/img/yewu.png" />
            <div>业务平台</div>
          </el-col>
        </a>
          <!-- <router-link to="/Business">
          <el-col  class="menu-item">
            <img src="../assets/img/yewu.png" />
            <div>业务平台</div>
          </el-col>
        </router-link> -->
        <router-link to="/Operation">
          <el-col  class="menu-item">
            <img src="../assets/img/zhinan.png" />
            <div>帮助中心</div>
          </el-col>
        </router-link>
        <router-link to="/About">
          <el-col  class="menu-item">
            <img src="../assets/img/guanyu.png" />
            <div>关于我们</div>
          </el-col>
        </router-link>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Search from "../components/Head.vue";
export default {
  name: "MenuList",
  components: { Search },
  data() {
    return {
      search: "",
      path:''
    };
  },
  created(){
 
  },
  mounted(){
     this.path = this.$route.path;
     console.log(this.path)
  },
  methods:{
    goIndex(){
      this.$router.push('/index') 
    },
  },
  watch:{
      $route(to,from){
        this.path = to.path
        console.log( this.path)
      }
   }
  
};
</script>
<style lang="less" scoped>
.menu {
 width: 1200px;
 margin: 0 auto;
}
.menu .menu-item {
  // padding: 5px 0;
  display: flex;
  align-items: center;
      justify-content: center;
  color: #000000;
  white-space: nowrap;
}
.menu-item:hover::before {
  //  border-bottom: 5px solid red;
  content: "";
  display: block;
  position: absolute;
  bottom: 5px;
  width: 60px;
  height: 4px;
  background: #004da1;
}
.menu-item {
  color: #000000;
  display: flex;
  align-items: center;
  line-height: 32px;
  // margin: 0 5px;
  padding: 0 10px;
  padding-bottom: 15px;

  img {
    width: 20px;
    height: 18px;
    margin: 0 8px 0 0;
  }
}
.aaa {
  display: flex;
  align-items: center;
  .logo {
    padding: 20px 0 23px 0;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
