<template>
  <div>
    <el-row>
      <!-- <el-col :span="8" class="logo" >
        <img src="../assets/img/LOGO.png" />
      </el-col> -->
      <el-col :span="24" class="showImg">
        <img src="../assets/img/16.png" />
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>债权转受让流程</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row type="flex" align="center"  class="process">
      <el-col  :xs="3" :md="3"  :lg="3" :xl="4"  class="left-box">
        <el-col :span="8">
         <router-link to="/Download"> 
          <div class="item-box" >
           <div class="img"></div>
            <span>APP下载</span>
          </div>
          </router-link>
        </el-col>
        <el-col :span="8">
          
          <div class="item-box1" @click="goDownload('#WX')">
            <div class="img1"></div>
            <span>微信小程序</span>
          </div>
         
        </el-col>
        <el-col :span="8">
          <div class="item-box2" @click="call_me('#call')">
            <div class="img2"></div>
            <span>联系我们</span>
          </div>
        </el-col>
      </el-col>
      <el-col :lg="21" :xl="16"> 
        <img src="../assets/img/18-1.png" style="width:100%;" />
      </el-col>
    </el-row>
    <el-row>
      <el-col class="concept-box">
        <img src="../assets/img/19.png" style="width:100%;" />
        <div class="concept-title">
          <img src="../assets/img/left-1.png">
        <span>债权流转概念</span>
        <img src="../assets/img/right-1.png">
        </div>
        <el-row class="concept-con" type="flex" justify="center">
          <el-col :lg="24" :xl="24">
            <span>债权流转，是指持有中债通和当地应收账款支付公司认证的应收账款债权的用户，通过债权收购、债权转让、债权消费等系列行为，将手中“死”的应收账款债权“活”起来的过程，债权流转可以有效盘活企业应收账款，化解政府性债务，缓解融资难、融资贵，服务实体经济发展。</span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
       <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>债权流转规则</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row>
      <el-row class="rules">
        <el-col :xs="8" :md="6" :lg="8" :xl="8" class="rules-item" style="background:#EEF5FF
;">
          <img src="../assets/img/21.png" />
          <span class="rules-title">转让定价</span>
          <span class="rules-con">债权转让价格由债权持有人（债权出让人）自主设定，系统提供参考价。</span>
        </el-col>
        <el-col :xs="8" :md="6" :lg="8" :xl="8" class="rules-item" style="background:#D4E3F3;">
          <img src="../assets/img/22.png" />
          <span class="rules-title">注册开户(债权账户)</span>
          <span class="rules-con">客户参与应收账款债权流转业务，应事先开立用于登记债权的债权账户。</span>
        </el-col>
        <el-col :xs="8" :md="6" :lg="8" :xl="8" class="rules-item" style="background:#EEF5FF
;">
          <img src="../assets/img/23.png" />
          <span class="rules-title">债权转让</span>
          <span
            class="rules-con"
          >应收账款债权持有人可通过中债通服务平台发布债权折价转让信息，平台上的其他客户选择受让合适的债权，双方达成合意后可在网站上进行债权转让。</span>
        </el-col>
      </el-row>
      <el-row class="rules">
        <el-col :xs="8" :md="6" :lg="8" :xl="8" class="rules-item" style="background:#D4E3F3;">
          <img src="../assets/img/24.png" />
          <span class="rules-title">服务时段</span>
          <span
            class="rules-con"
          >平台提供7×24小时全天候线上服务，后台客服工作时间为周一至周五，上午9：00-12：00，下午14：00-16：00，平台可根据市场情况调整线上和后台服务时段。</span>
        </el-col>
        <el-col :xs="8" :md="6" :lg="8" :xl="8" class="rules-item" style="background: #EEF5FF
;">
          <img src="../assets/img/25.png" />
          <span class="rules-title">转让信息发布</span>
          <span
            class="rules-con"
          >债权持有人可以在平台服务时段内发布债权转让信息，转让信息发布成功系统自动生成对应的转让订单，并冻结对应的债权，在转让成功、取消转让或转让自动失效前，不能用被冻结的债权办理其它业务。</span>
        </el-col>
        <el-col :xs="8" :md="6" :lg="8" :xl="8" class="rules-item" style="background:#D4E3F3;">
          <img src="../assets/img/26.png" />
          <span class="rules-title">信息有效期</span>
          <span
            class="rules-con"
          >转让有效期默认14天有效，且不得大于债权到期前三天，有效期从信息发布成功之时开始连续计算，债权持有人可在有效期内取消未转让的债权转让信息，超出有效期而未转让的信息将自动失效，并解冻对应的债权。</span>
        </el-col>
      </el-row>
    </el-row>
    <el-row type="flex" >
      <el-col class="service">
        <img src="../assets/img/27.png" class="bg" />
        <div class="service-title">
          <img src="../assets/img/left-1.png">
        <span>中债通提供的服务</span>
        <img src="../assets/img/right-1.png">
        </div>
        <el-row class="service-box" type="flex" justify="center">
          <el-col  :lg="5" :xl="5">
            <img src="../assets/img/28.png" />
            <span>系统全程搭建工作指导、包括设立新公司的要素、请示文件的撰写，相关主体的谈判等</span>
          </el-col>
          <el-col :lg="5" :xl="5">
            <img src="../assets/img/29.png" />
            <span>24小时系统故障服务响应系统</span>
          </el-col>
          <el-col :lg="5" :xl="5">
            <img src="../assets/img/30.png" />
            <span>政府相关工作人员系统使用的培训</span>
          </el-col>
          <el-col :lg="5" :xl="5">
            <img src="../assets/img/31.png" />
            <span>使用区块链技术对债权登记进行加密，防止系统数据丢失或篡改</span>
          </el-col>
          <el-col :lg="5" :xl="5">
            <img src="../assets/img/32.png" />
            <span>根据区域具体要求进行系统要素的调整</span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
        <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>应收账款债权凭证（合同）</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row>
      <el-col class="contract">
        <img src="../assets/img/33.png" />
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted(){},
   methods:{
     call_me(id){
        this.$router.push({name:'About',path:'/About',params:{
           id:id
        }})
     },
     goDownload(id){
        if(this.$route.path=='/Download'){
           document.querySelector(id).scrollIntoView(true);
        }else{
           this.$router.push({name:'Download',path:'/Download',params:{
           id:id
        }})
        }
      }
   }
}
</script>
<style lang="less" scoped>
.showImg {
  img {
    width: 100%;
  }
}
.con-title {
 display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
        width: 1200px;
  span {
    justify-content: center;
    display: flex;
    color: #004da1;
    font-size: 26px;
  }
  img{
     padding: 0 40px;
   // margin: 0 40px;
  }
}
.process{
  padding-bottom: 20px;
    width: 1200px;
    margin: 0 auto;
  .left-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
      // width: 100px;
  .item-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 
 .img {
    background: url("../assets/img/15.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
    color: #333;
  }
}
}
.item-box:hover{
    .img{
    background: url("../assets/img/15-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
 .left-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
      // width: 100px;
  .item-box1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 
 .img1 {
    background: url("../assets/img/13.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
    color: #333;
  }
}
.item-box1:hover{
    .img1{
    background: url("../assets/img/13-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
  .left-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
      // width: 100px;
  .item-box2 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 
 .img2 {
    background: url("../assets/img/14.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
    color: #333;
  }
}
.item-box2:hover{
    .img2{
    background: url("../assets/img/14-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
.concept-box {
      line-height: 25px;
  .concept-title {
    position: absolute;
    left: 50%;
    top: 20px;
    z-index: 1100;
    transform: translate(-50%, 26px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
    span {
      font-size: 26px;
      color: #fff;
    }
    img{
      padding: 0 40px;
    }
  }
  .concept-con {
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1100;
    width: 1200px;
        margin-top: 20px;
      // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    line-clamp: 2;
  }
  .el-col{
    text-align: justify;
  }
}
.rules {
  width: 1200px;
    margin: 0 auto;
  .rules-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 273px;
    max-height: 273px;
    // min-width: 400px;
    .rules-title {
      padding: 10px 0;
      color: #004da1;
      font-size: 22px;
    }
    .rules-con {
      margin: 5px;
      color: #777;
      width: 250px;
      font-size: 14px;
      padding-bottom: 20px;
      text-align: justify;
    }
    img{
      width: 60px;
      height: 60px;
      padding-top: 20px;
    }
  }
}
@media screen and (max-width: 1200px) {
    .rules {
        display: flex;
        justify-content: center;
        width: 1200px;
    }
}

// @media screen and (max-width: 1200px) {
//     .process {
//         display: flex;
//         justify-content: center;
//         width: 1200px;
//     }
// }
.service {
  padding-top: 20px;
  .bg {
    width: 100%;
    height: 340px;
  }
  .service-title {
    position: absolute;
    left: 50%;
    top: 30px;
    z-index: 1100;
    transform: translate(-50%, 26px);
    display: flex;
    align-items: center;
    justify-content: center;
        width: 100%;
    span {
      font-size: 26px;
      color: #fff;
    }
    img{
      padding:0 40px;
    }
  }
  .service-box {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -25%);
    width: 1200px;
    .el-col {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin: 10px;
      text-align: justify;
      span {
        width: 170px;
        margin: 20px 0;
        color: #fff;
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}
.contract {
  text-align: center;
  padding-bottom: 20px;
}
 .item-box span:hover{
    color: #004da1;
  }
</style>