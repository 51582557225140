<template>
  <div>
    <el-row>
      <el-col>
        <img src="../assets/img/44.png" style="width:100%;" />
      </el-col>
    </el-row>
    <el-row class="platform-title" type="flex" justify="center">
      <el-col :xs="12" :lg="22" :xl="18" push="1">
        <span>业务平台</span>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="4" :lg="4" :xl="6"  class="left-box">
        <el-col :span="8">
           <router-link to="/Download"> 
          <div class="item-box">
            <img src="../assets/img/15.png" />
            <span>APP下载</span>
          </div>
           </router-link>
        </el-col>
        <el-col :span="8">
          
          <div class="item-box"  @click="goDownload('#WX')">
            <img src="../assets/img/13.png" />
            <span>微信小程序</span>
          </div>
         
        </el-col>
        <el-col :span="8">
          <div class="item-box">
            <img src="../assets/img/14.png" />
            <span>联系我们</span>
          </div>
        </el-col>
      </el-col>
      <el-col :xs="20" :lg="20" :xl="20" style="margin-bottom:60px;">
        <el-row class="platform">
          <div >
            <el-col :xs="7" :lg="7" :xl="5" class="platform-item">
              <img src="../assets/img/45.png" class="item-bg" />
              <div @click="personalLogin" style="cursor: pointer;">
                <el-col class="item-title" style="background: rgba(89, 190, 244, 0.8);" >
                <span>企业和个人客户专区</span>
                <div >（点击登录）</div>
                <img src="../assets/img/63.png">
              </el-col>
              </div>
              
            </el-col>
          </div>
          <div>
            <el-col :xs="7" :lg="7" :xl="5" class="platform-item">
              <img src="../assets/img/46.png" class="item-bg" />
              <div @click="collectlLogin" style="cursor: pointer;">
              <el-col   class="item-title" style="background: rgba(84, 211, 192, 0.8);">
                <span>应收账款确认公司专区</span>
                <div>（点击登录）</div>
                <img src="../assets/img/63.png">
              </el-col>
              </div>
            </el-col>
          </div>
          <div>
            <el-col :xs="7" :lg="7" :xl="5" class="platform-item">
              <img src="../assets/img/47.png" class="item-bg" />
              <div @click="payLogin" style="cursor: pointer;">
              <el-col class="item-title" style="background: rgba(110, 175, 247, 0.8);">
                <span>应收账款支付公司专区</span>
                <div>（点击登录）</div>
                <img src="../assets/img/63.png">
              </el-col>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row class="platform">
          <div>
            <el-col :xs="7" :lg="7" :xl="5" class="platform-item">
              <img src="../assets/img/48.png" class="item-bg" />
              <div @click="financialLogin" style="cursor: pointer;">
              <el-col class="item-title" style="background: rgba(181, 49, 0, 0.8);">
                <span>金融机构专区</span>
                <div>（点击登录）</div>
                <img src="../assets/img/63.png">
              </el-col>
              </div>
            </el-col>
          </div>
          <div>
            <el-col :xs="7" :lg="7" :xl="5" class="platform-item">
              <img src="../assets/img/50.png" class="item-bg" />
              <div @click="governmentLogin"  style="cursor: pointer;">
              <el-col class="item-title" style="background: rgba(0, 77, 161, 0.8);">
                <span> 政府监管机构专区</span>
                <div>（点击登录）</div>
                <img src="../assets/img/63.png">
              </el-col>
              </div>
            </el-col>
          </div>
          <div>
            <el-col :xs="7" :lg="7" :xl="5" class="platform-item">
              <img src="../assets/img/49.png" class="item-bg" />
              <div @click="cooperationLogin" style="cursor: pointer;">
              <el-col class="item-title" style="background: rgba(233, 164, 0, 0.8);">
                <span>合作机构专区</span>
                <div>（点击登录）</div>
                <img src="../assets/img/63.png">
              </el-col>
              </div>
            </el-col>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data(){
    return{
    }
  },
  methods:{
     goDownload(id){
        if(this.$route.path=='/Download'){
           document.querySelector(id).scrollIntoView(true);
        }else{
           this.$router.push({name:'Download',path:'/Download',params:{
           id:id
        }})
        }
      },
    personalLogin(){
    
      this.$router.push("/PersonalLogin")
    },
    collectlLogin(){
       this.$router.push("/CollectLogin")
    },
    payLogin(){
      this.$router.push("/PayLogin")
    },
    financialLogin(){
      this.$router.push('/FinancialLogin')
    },
    governmentLogin(){
      this.$router.push('/GovernmentLogin')
    },
    cooperationLogin(){
      this.$router.push('/CooperationLogin')
    }
  }
};
</script>
<style lang="less" scoped>
.platform-title {
  text-align: center;
  padding: 10px 0;
  color: #004da1;
  font-size: 25px;
}
.left-box {
  display: flex;
  flex-flow: column;
  align-items: center;
      margin: 5px 10px;
  .item-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #eeeeef;
    margin: 5px 0;
  }
  img {
    width: 30px;
    height: 30px;
  }
  span{
    font-size: 12px;
  }
}
.platform {
    padding: 10px 0 ;
  .platform-item {
    position: relative;
    margin: 0 5px;
    .item-bg {
      width: 100%;
      height: 200px;
    }
    .item-title {
      position: absolute;
      bottom: 3px;
      padding: 10px;
      color: #fff;
      font-size: 14px;
      width: 100%;
      white-space: nowrap;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
      }
      div{
        font-size: 12px;
      }
      img{
        width: 10px;
        height: 17px;
        margin-left: auto;
      }
    }
  }
}
</style>