<template>
    <el-row class="head-top">
  <el-col :span="24">
      <el-col :lg="11" class="head-serach">
          <div class="serach">
        <input v-model="search" placeholder="请输入关键字" placeholder-class="aaa" />
        <img src="../assets/img/search.png"/>
    </div>
      </el-col>
    
  </el-col>
</el-row>
</template>
<script>
export default {
    name:'search',
    data(){
        return{
            search:''
        }
    },  
}
</script>
<style lang="less" scoped>
.head-top{
    // padding-top: 10px;
}
.head-serach{
    float: right;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}
 .serach{
    display: flex;
    align-items: center;
    float: right;
    width: 165px;
    height: 22.5px;
    border: 1px solid #8888;
    input{
        border: none;
        line-height: 20px;
        outline: none;
        margin: 0 5px;
        width: 75%;
        font-size: 10px;
    }
    .aaa{
        font-size: 12px;
    }
}

</style>
