<template>
  <div>
    <el-row>
      <el-col>
        <img src="../assets/img/85.jpg" style="width: 100%;" />
      </el-col>
    </el-row>
    <el-row type="flex" class="ArticleCon">
      <el-col  :md="6" :lg="6" :xl="6" class="left_box">
        <div class="tabs">
          <!-- <el-col :span="5" v-for="(tab,index) in tabsName"> -->
          <el-col class="tabs_title" v-for="(tab,index) in tabsName" :key="tab.id">
            <div class="tab-link" @click="tabsSwitch(index)" v-bind:class="{active:tab.isActive}">
              <div>{{tab.name}}</div>
            </div>
          </el-col>
        </div>
      </el-col>
      <el-col>
        <el-row type="flex" align="middle" justify="end" class="Breadcrumb">
          <span>当前位置:</span>
          <router-link to="/Index">
            <span>首页</span>
          </router-link>
          <span>></span>
          <span>{{BreName}}</span>
        </el-row>
        <el-row style="margin-left: 50px;">
          <el-col>
            <div class="tab-card" style="display: block;">
              <el-row style="padding-bottom: 20px;">
                <el-col class="ArticleTitle">
                  <span>{{BreName}}</span>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle" class="content" v-for="item in ImprotantArticle" :key="item.id">
                <el-col :lg="1" :xl="1">
                  <img src="../assets/img/86.png" />
                </el-col>
                <el-col :lg="20" :xl="20" class="title">
                  <span v-if="item.tags!=''" @click="go_Improtantdetail(item.id)" style="color:red;">{{item.title}}</span>
                   <span v-else @click="go_Improtantdetail(item.id)">{{item.title}}</span>
                </el-col>
                <el-col :lg="3" :xl="3" class="time">
                  <span>{{item.release_time}}</span>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle" class="content" v-for="item in ArticleList" :key="item.id">
                <el-col :lg="1" :xl="1">
                  <img src="../assets/img/86.png" />
                </el-col>
                <el-col :lg="20" :xl="20" class="title">
                  <span v-if="item.tags!=''" @click="look_detail(item.id)">{{item.title}}</span>
                   <span v-else @click="look_detail(item.id)">{{item.title}}</span>
                </el-col>
                <el-col :lg="3" :xl="3" class="time">
                  <span>{{item.release_time}}</span>
                </el-col>
              </el-row>
            </div>
            <!-- <div class="tab-card" style="display: none;">
              <el-row style="padding-bottom: 20px;">
                <el-col class="ArticleTitle">
                  <span>{{BreName}}</span>
                </el-col>
              </el-row>
            </div>
            <div class="tab-card" style="display: none;">
              <el-row style="padding-bottom: 20px;">
                <el-col class="ArticleTitle">
                  <span>{{BreName}}</span>
                </el-col>
              </el-row>
            </div>
            <div class="tab-card" style="display: none;">
              <el-row style="padding-bottom: 20px;">
                <el-col class="ArticleTitle">
                  <span>{{BreName}}</span>
                </el-col>
              </el-row>
            </div>
            <div class="tab-card" style="display: none;">
              <el-row style="padding-bottom: 20px;">
                <el-col class="ArticleTitle">
                  <span>{{BreName}}</span>
                </el-col>
              </el-row>
            </div> -->
          </el-col>
          <el-col class="FY">
              <el-pagination
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum">
    </el-pagination>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {ArticleList,ImprotantArticle} from "../api/article"
export default {
  data() {
    return {
      tabsName: [
        {
          name: "相关政策法规",
          isActive: true
        },
        // {
        //   name: "行业热点",
        //   isActive: false
        // },
        // {
        //   name: "行业新闻",
        //   isActive: false
        // },
        // {
        //   name: "行业动态",
        //   isActive: false
        // },
        // {
        //   name: "通知公告",
        //   isActive: false
        // }
      ],
      BreName: "相关政策法规",
      ArticleList:[],
      ImprotantArticle: [],
      currentPage:1,
      totalNum:0,
      size:10
    };
  },
  mounted() {
    this.get_list()
    this.get_improtant()
  },
  methods: {
    // 切换页签
     handleCurrentChange(val) {
        this.currentPage++
        this.get_list()
    this.get_improtant()
      },
    tabsSwitch: function(tabIndex) {
      var tabCardCollection = document.querySelectorAll(".tab-card"),
        len = tabCardCollection.length;
      for (var i = 0; i < len; i++) {
        tabCardCollection[i].style.display = "none";
        this.tabsName[i].isActive = false;
      }
      this.tabsName[tabIndex].isActive = true;
      this.BreName = this.tabsName[tabIndex].name;
      tabCardCollection[tabIndex].style.display = "block";
    },
    // 获取重要文章
     // 获取重要文章接口
    get_improtant() {
        var data = {
        page: this.currentPage,
        size: this.size,
      };
ImprotantArticle(data).then(res=>{
  console.log('264556',res)
this.ImprotantArticle=res.data.data.data
this.totalNum=res.data.data.total
})
    },
    // 重要文章跳转
     go_Improtantdetail(id){
      this.$router.push({
        name: "ArticleDetail",
        params: {
          ID: id,
        },
      });
    },
    // 文章详情跳转
    look_detail(id){
        this.$router.push({
          name:'ArticleDetail',
          params: {
            id: id
          }
        })
    },
    // 获取文章列表
    get_list(){
        var data={
        page:this.currentPage,
        size:this.size
      }
      ArticleList(data).then(res=>{
        console.log('1545646',res)
       this.ArticleList=res.data.data.data
       this.totalNum +=res.data.data.total
       console.log(this.totalNum)
      })
    }
  }
};
</script>
<style lang="less" scoped>
.Breadcrumb{
a {
  text-decoration: none;
  color: #004da1;
}
}

.ArticleCon {
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0;
  .left_box {
    height: 600px;
    box-shadow: 0px 10px 10px 1px #ddd;
    cursor: pointer;
  }
  .tabs {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    .tab-link {
      padding: 20px 10px;
    }
    .tabs_title {
      border-bottom: 1px solid #fff;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }
    .active {
      padding: 5px 0;
      color: #fff;
      background: #004da1;
      padding: 20px 10px;
      // border-bottom: 4px solid #004da1;
    }
  }
  .Breadcrumb {
    text-align: center;
    font-size: 14px;
    color: #444444;
    font-weight: 400;
    padding: 20px 0;
    span {
      padding: 0 0 0 5px;
    }
  }
  .tab-card {
        box-shadow: 1px 1px 0px 0px #ddd;
    // margin-left:50px ;
    height: 540px;
    .ArticleTitle {
      background: #e4ecfa;
      padding: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #004da1;
    }
    .content {
          padding: 5px 30px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      .time {
        text-align: right;
        font-size: 14px;
      }
      .title {
        span {
          width: 645px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      img {
        width: 14px;
        height: 14px;
        border: 0;
      }
    }
  }
  .FY{
      position: absolute;
    bottom: 0;
    text-align: right;
    padding: 20px 0;
  }
}
</style>