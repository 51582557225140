<template>
  <div style="background: #f6f7f8; min-width: 1200px">
    <el-row>
      <el-col :xs="24" :lg="24" :xl="24" style="min-width: 1200px">
        <el-carousel
          :interval="3000"
          arrow="always"
          v-if="screenWidth >= 750"
          :height="bannerHeight + 'px'"
        >
          <el-carousel-item v-for="item in superurl" :key="item.id">
            <img :src="item.img" style="width:100%;"   :height="bannerHeight + 'px'" />
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          :interval="3000"
          arrow="always"
          v-else
          :height="samllHeight + 'px'"
        >
          <el-carousel-item v-for="item in superurl" :key="item.id">
            <img :src="item.img" style="width: 100%" />
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <div style="background: #fff; min-width: 1200px">
      <el-row class="notice" type="flex" justify="center" align="middle">
        <el-col :xs="2" :lg="2" :xl="2">
          <span style="text-align: left" class="notice_title">通知公告:</span>
        </el-col>
        <el-col :xs="12" :lg="12" :xl="12" class="notice-con">
          <ul class="bbb">
            <li v-for="item in arr" v-show="item.isshow" :key="item.id">
              <a :href="item.url">{{ item.name }}</a>
            </li>
          </ul>
        </el-col>
        <el-col :xs="10" :lg="10" :xl="10" class="notice-con">
          <ul class="bbb">
            <li v-for="item in arr2" v-show="item.isshow" :key="item.id">
              <router-link :to="item.url">{{ item.name }}</router-link>
            </li>
          </ul>
        </el-col>
        <el-col :xs="2" :lg="1" :xl="2" class="noticeBtn">
          <img src="../assets/img/jiantou.png" @click="aaa" />
        </el-col>
      </el-row>
    </div>
    <el-row
      type="flex"
      align="center"
      justify="center"
      style="padding: 20px 0 20px 0; width: 1200px; margin: 0 auto"
    >
      <el-col :xs="12" :lg="12" :xl="14" class="sweper">
        <!-- <el-carousel :height="samllHeight + 'px'" arrow="always" :interval="3000"> -->
        <el-carousel
          height="356px"
          arrow="always"
          :interval="3000"
          indicator-position="none"
        >
          <el-carousel-item v-for="item in samllSweper" v-if="item.thumb!=0" :key="item.id" >
            <img :src="item.thumb" @click="go_detail(item.id)" />
            <div class="carousel_text" @click="go_detail(item.id)">
              <h3>{{ item.title }}</h3>
              <p>{{ item.summary }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :xs="12" :lg="12" :xl="14" class="News-tabs">
        <div class="rightCon">
          <div class="tabs">
            <!-- <el-col :span="5" v-for="(tab,index) in tabsName"> -->
            <el-col :span="8" v-for="(tab, index) in tabsName" :key="tab.id">
              <div
                class="tab-link"
              
                v-bind:class="{ active: tab.isActive }"
              >
                <!-- @click="tabsSwitch(index)" -->
                <div>{{ tab.name }}</div>
              </div>
            </el-col>
            <el-col
              :span="4"
              style="margin-left: auto; font-size: 14px; color: #ddd"
              ><router-link to="/Article"> 更多</router-link></el-col
            >
          </div>
          <div class="tab-card" style="display: block">
            <div v-for="item in ImprotantArticle" :key="item.id" class="improtant">
              <div v-if="item.status==1"  @click="go_Improtantdetail(item.id)">
              <el-row >
                <el-col :span="24" class="big_title">{{ item.title }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="24"  class="improtant_title"  >{{item.summary}}</el-col>
              </el-row>
              </div>
            </div>
            <div v-for="item in samllSweper" :key="item.id">
              <div @click="go_detail(item.id)">
                <!-- <span class="improtant_title">{{ item.title }}</span> -->
                <!-- <p>{{item.summary}}</p> -->
              </div>
              <el-row>
                <el-col :span="18" class="text-title" ><div class="title00"  @click="go_detail(item.id)">{{ item.title }}</div></el-col>
                <el-col :span="6" class="text-time">{{item.release_time}}</el-col>
              </el-row>
            </div>
          </div>
          <div class="tab-card" style="display: none">
            <span>新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</span>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
          </div>
          <div class="tab-card" style="display: none">
            <span>新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</span>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
          </div>
          <div class="tab-card" style="display: none">
            <span>新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</span>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
            <el-row>
              <el-col :span="18" class="text-title"
                >小标题小标题小标题小标题小标题小标题小标题小小标题小标题小标题小标题小标题小标题小标题小</el-col
              >
              <el-col :span="6" class="text-time">2021 - 08- 05</el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="business">
      <el-col :lg="16" :xl="16">
        <el-row
          style="padding: 10px 0; margin: 0 0 0 -10px"
          :gutter="20"
          type="flex"
          align="center"
        >
          <el-col :xs="8" :lg="8" :xl="8" class="business_item" >
            <div class="business-box" @click="Circulation">
              <img
                src="../assets/img/1.png"
                style="width: 88px; margin-top: 30%"
              />
              <el-col class="business-con">
                <el-row class="business-title">债权流转</el-row>
                <el-row class="business-text">
                  盘活企业应收账款，化解政府性债务，缓解融资难、融资贵。服务实体经济发展。</el-row
                >
              </el-col>
            </div>
          </el-col>
          <el-col :xs="8" :lg="8" :xl="8" style="padding-right: 0">
            <div
              class="business-box"
              style="background: #199fe8"
              @click="Pledge"
            >
              <img src="../assets/img/3.png" />
              <el-col class="business-con">
                <el-row class="business-title">债权质押</el-row>
                <el-row class="business-text"
                  >有效盘活现有债权，增加政、企、银优质资产，增加中小企业融资投放渠道。</el-row
                >
              </el-col>
            </div>
          </el-col>
          <el-col :xs="8" :lg="8" :xl="8" class="business_item">
            <div
              class="business-box"
              style="background: #0fb199"
              @click="Offset"
            >
              <el-col class="business-con">
                <el-row class="business-title">债权抵消</el-row>
                <el-row class="business-text">
                  化解企业、政府、金融机构等各类经济主体间的“三角债”，解开债务链。
                </el-row>
              </el-col>
              <img src="../assets/img/2.png" />
            </div>
          </el-col>
        </el-row>
        <el-col :lg="24" :xl="24" class="NewsBigimg">
          <!-- <img src="../assets/img/4.jpg" />
          <el-col  class="NewsTitle">社会责任</el-col>-->
          <el-carousel
            height="360px"
            arrow="always"
            :interval="3000"
            indicator-position="none"
            direction="horizontal"
            class="footerNews"
          >
            <el-carousel-item v-for="item in footerSweper" :key="item.id">
              <router-link to="/About">
                <img :src="item.img" />
                <div class="footerSweper">{{item.title}}</div>
              </router-link>
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-col>
      <el-col :xs="8" :lg="8" :xl="8" class="zone-con">
        <el-col style="padding-left: 35px">
          <el-row class="zone-item" style="background: #fff">
            <div @click="BusinessItem">
              <el-col  :md="12" :xs="12" :lg="12" class="zone zone-1">
                <el-col>
                  <img src="../assets/img/5.png" />
                </el-col>
                <el-col>
                  <span>企业和个人客户专区</span>
                </el-col>
              </el-col>
            </div>
            <div @click="BusinessItem">
              <el-col  :md="12" :xs="12" :lg="12" class="zone zone-2">
                <el-col>
                  <img src="../assets/img/8.png" />
                </el-col>
                <el-col>
                  <span>政府应收账款确认公司</span>
                </el-col>
              </el-col>
            </div>
          </el-row>

          <el-row style="background: #fff">
            <div @click="BusinessItem">
              <el-col  :md="12" :xs="12" :lg="12" class="zone zone-3">
                <el-col>
                  <img src="../assets/img/7.png" />
                </el-col>
                <el-col>
                  <span>政府应收账款支付公司</span>
                </el-col>
              </el-col>
            </div>
            <div @click="BusinessItem">
              <el-col :md="12" :xs="12" :lg="12" class="zone">
                <el-col>
                  <img src="../assets/img/6.png" />
                </el-col>
                <el-col>
                  <span>金融机构专区</span>
                </el-col>
              </el-col>
            </div>
          </el-row>
          <el-row class="box-con">
            <el-col :xs="24" :lg="24" class="introduce-con">
              <img src="../assets/img/9.png" @click="Dowup" />
              <a @click="Dowup">扫码或转让债权，请下载最新app</a>
            </el-col>
            <el-col :xs="24" :lg="24" class="introduce-con">
              <img src="../assets/img/12.png" @click="BusinessItem" />
              <a @click="BusinessItem"
                >金融企业、监管机构请移步业务平台进行登录</a
              >
            </el-col>
          </el-row>
        </el-col>
      </el-col>
    </el-row>
    <!-- <footer-box></footer-box> -->
  </div>
</template>
<script>
import Search from "../components/Head.vue";
import MenuList from "../components/MenuList.vue";
import FooterBox from "../components/Footer.vue";
import TopBox from "../components/Top.vue";
import { ArticleList, Swiper, ImprotantArticle,Notice } from "../api/article";
export default {
  name: "Index",
  components: { Search, MenuList, FooterBox, TopBox },
  data() {
    return {
      superurl: [],
      bannerHeight: 550,
      screenWidth: 0,
      samllHeight: 300,
      samllSweper: [
        {
          url: "./Article",
          img: require("../assets/img/sweper-1.png"),
          title:
            "我是第一个标题我是第一个标题我是第一个标题我是第一个标题我是第一个标题我是第一个标题",
          summary:
            "我是第一个内容我是第一个内容我是第一个内容我是第一个内容我是第一个内容我是第一个内容我是第一个内容我是第一个内容我是第一个内容我是第一个内容",
        },
      ],
      footerSweper: [],
      // tabsName: [
      //   {
      //     name: "行业热点",
      //     isActive: true
      //   },
      //   {
      //     name: "行业新闻",
      //     isActive: false
      //   },
      //   {
      //     name: "行业动态",
      //     isActive: false
      //   },
      //   {
      //     name: "通知公告",
      //     isActive: false
      //   }
      // ],
      tabsName: [
        {
          name: "相关法规和政策",
          isActive: true,
        },
      ],
      arr: [
        {
          name: "•登录查看：注册用户登录业务平台，查看债权详细信息。",
          url: `http://www.zhaitongyun.com`,
          isshow: true,
        },
        {
          name: "•登录查看：注册用户登录业务平台，查看债权详细信息。",
          url: `http://www.zhaitongyun.com`,
          isshow: false,
        },
        {
          name: "•登录查看：注册用户登录业务平台，查看债权详细信息。",
          url: `http://www.zhaitongyun.com`,
          isshow: false,
        },
        {
          name: "•登录查看：注册用户登录业务平台，查看债权详细信息。",
          url: `http://www.zhaitongyun.com`,
          isshow: false,
        },
      ],
      arr2: [
        {
          name:
            "•免费注册：各类市场主体登录中债通网或客户端（手机APP、微信小程序）免费注册成为中债通会员，可选择任一国有独资的债权管理公司作为债权管理主体。",
          url: "/Download",
          isshow: true,
        },
        {
          name:
            "•免费注册：各类市场主体登录中债通网或客户端（手机APP、微信小程序）免费注册成为中债通会员，可选择任一国有独资的债权管理公司作为债权管理主体。",
          url: "/Download",
          isshow: false,
        },
        {
          name:
            "•免费注册：各类市场主体登录中债通网或客户端（手机APP、微信小程序）免费注册成为中债通会员，可选择任一国有独资的债权管理公司作为债权管理主体。",
          url: "/Download",
          isshow: false,
        },
        {
          name:
            "•免费注册：各类市场主体登录中债通网或客户端（手机APP、微信小程序）免费注册成为中债通会员，可选择任一国有独资的债权管理公司作为债权管理主体。",
          url: "/Download",
          isshow: false,
        },
      ],
      i: 0,
      ImprotantArticle: [],
    };
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    this.setSize();
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.setSize();
    };
    this.get_improtant();
    this.get_Article();
    this.get_Topswiper();
    this.get_Footerswiper();

  },
  methods: {
    // 获取重要文章接口
    get_improtant() {
        var data = {
        page: 1,
        size: 10,
      };
ImprotantArticle(data).then(res=>{
  console.log('264556',res)
this.ImprotantArticle=res.data.data.data
})
    },
    // 获取公告接口
    // get_notice() {
    //   var data = {
    //     page: 1,
    //     size: 10,
    //   };
    //   Notice(data).then((res) => {
    //     this.ImprotantArticle = res.data.data.data;
    //     this.ImprotantArticle.forEach(v=>{
    //       console.log('我是后台数据',v)
    //       this.arr2.forEach(vv=>{
    //         console.log('我是前端数据',vv)
    //  v.title=vv.title
    //       })
    //     })
    //   });
    // },
    // 请求文章轮播图
    get_Article() {
      var data = {
        page: 1,
        size: 8,
      };
      ArticleList(data).then((res) => {
        var aaa = res.data.data.data;
        console.log(aaa);
        this.samllSweper = aaa;
      });
    },
    // 获取顶部轮播图
    get_Topswiper() {
      var category_id = {
        category_id: 1,
      };
      Swiper(category_id).then((res) => {
        this.superurl = res.data.data;
      });
    },
    // 获取顶部轮播图
    get_Footerswiper() {
      var category_id = {
        category_id: 2,
      };
      Swiper(category_id).then((res) => {
        console.log(res)
        this.footerSweper = res.data.data;
      });
    },
    aaa() {
      if (this.i + 1 != this.arr.length && this.i + 1 != this.arr2.length) {
        this.i++;
        this.arr[this.i].isshow = true;
        this.arr[this.i - 1].isshow = false;
        this.arr2[this.i].isshow = true;
        this.arr2[this.i - 1].isshow = false;
      } else {
        this.arr[this.i].isshow = false;
        this.arr2[this.i].isshow = false;
        this.i = 0;
        if (this.i + 1 != this.arr.length && this.i + 1 != this.arr2.length) {
          this.arr[this.i].isshow = true;
          this.arr2[this.i].isshow = true;
        }
      }
    },
    setSize: function () {
      this.bannerHeight = (460 / 1920) * this.screenWidth;
      this.samllHeight = (1536 / 1920) * this.screenWidth;
      console.log(this.samllHeight);
    },
    // 下载跳转
    Dowup() {
      this.$router.push("/Download");
    },
    // 业务平台跳转
    BusinessItem() {
     window.open( "http://www.zhaitongyun.com");
    },
    // 选项卡切换事件
    // 选项卡事件
    tabsSwitch: function (tabIndex) {
      var tabCardCollection = document.querySelectorAll(".tab-card"),
        len = tabCardCollection.length;
      for (var i = 0; i < len; i++) {
        tabCardCollection[i].style.display = "none";
        this.tabsName[i].isActive = false;
      }
      this.tabsName[tabIndex].isActive = true;
      tabCardCollection[tabIndex].style.display = "block";
       this.get_Article()
    },
    // 债权流转跳转
    Circulation() {
      this.$router.push("/Circulation");
    },
    // 债权抵消跳转
    Offset() {
      this.$router.push("/Offset");
    },
    // 债权质押跳转
    Pledge() {
      this.$router.push("/Pledge");
    },
    // 通知公告跳转
    go() {
      this.$router.push("/Business");
    },
    // 跳转详情
    go_detail(id) {
      console.log(12312)
      this.$router.push({
        name: "ArticleDetail",
        params: {
          id: id,
        },
      });
    },
    // 跳转重要文章详情
    go_Improtantdetail(id){
      this.$router.push({
        name: "ArticleDetail",
        params: {
          ID: id,
        },
      });
    }
  },
};
</script>
<style lang='less' >
a {
  text-decoration: none;
  color: #333;
}
.search {
  width: 100%;
}
.el-col-2 {
  display: flex;
  align-items: center;
  color: #000000;
  line-height: 32px;
  img {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
}
.notice {
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0 10px 0;
  // background: #fff;
  .notice_title {
    color: #004ca0;
    font-weight: bold;
    font-size: 15px;
    white-space:nowrap
  }
  .el-col {
    font-size: 15px;
    span {
      color: #004da1;
    }
  }
  .noticeBtn {
    img {
      width: 15px;
      height: 15px;
    }

    margin: 0 5px;
    text-align: right;
  }
}
.notice-con {
  margin: -20px 0;
  .bbb {
    li {
      list-style: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
.sweper {
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.carousel_text {
  background: #333;
  opacity: 0.7;
  color: #fff;
  position: absolute;
  bottom: 0;
  z-index: 1100;
  padding: 10px 0;
  width: 100%;
  h3 {
    white-space: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 20px;
    line-height: 1.5;
  }
  p {
    margin: 0 20px;
    line-height: 1.8;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /*要显示的行数*/
    -webkit-box-orient: vertical;
  }
}
.News-tabs {
  margin-left: 40px;
  background: #fff;
  min-width: 510px;
  min-height: 356px;
  max-height: 361px;
  overflow: hidden;
}
.rightCon {
  padding: 5px 30px 15px;
  .tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    .el-col {
      font-size: 21px;
      text-align: center;
      cursor: pointer;
      .active {
        padding: 5px 0;
        color: #004da1;
        border-bottom: 4px solid #004da1;
      }
    }
    span {
      color: #004da1;
    }
  }
  .tab-card {
    font-size: 15px;
    margin-top: 10px;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .text-title {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      cursor: pointer;
      .titleStyle{
        white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      }
    }
    .text-title:hover {
      color: #0087d2;
    }
    .text-time {
      text-align: right;
      color: #bbbbbb;
    }
    .el-row {
      padding: 5px 0;
    }
    span {
      font-size: 21px;
      color: #004da1;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.business {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  width: 1200px;
  margin: 0 auto;
  .business-box {
    background: #0252aa;
    // width: 230px;
    height: 160px;
    position: relative;
    // max-width: 259px;
    cursor: pointer;
    img {
      float: right;
      margin-top: 25%;
      margin-right: 2%;
      width: 80px;
    }
    .business-con {
      position: absolute;
      z-index: 1100;
      top: 0;
      padding: 10px;
      color: #fff;
      font-size: 17px;
      margin-left: 5%;
      .el-row {
        padding: 5px 0;
      }
      .business-title {
        font-size: 17px;
        font-weight: 600;
      }
    }
    .business-text {
      width: 160px;
      min-width: 160px;
      // letter-spacing: 2px;
      text-align: justify;
    }
  }
}
.NewsBigimg {
  position: relative;
  height: 360px;
  // margin: 0 5px;
  padding: 20px 0;
  img {
    width: 100%;
    height: 360px;
  }
  .NewsTitle {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    height: 40px;
    line-height: 40px;
    color: #fff;
    padding: 0 20px;
    text-align: center;
  }
  .footerNews {
    .el-carousel__arrow {
      height: 360px;
      border-radius: 0;
      width: 100px;
      //  background: rgba(0, 0, 0, 0.04);
    }
    .el-carousel__arrow i {
      font-size: 31px;
      font-weight: 800;
    }
    .el-carousel__arrow--left {
      background-image: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0.5) 0,
        rgba(0, 0, 0, 0.0001) 100%
      );
      background-image: -o-linear-gradient(
        left,
        rgba(0, 0, 0, 0.5) 0,
        rgba(0, 0, 0, 0.0001) 100%
      );
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(0, 0, 0, 0.5)),
        to(rgba(0, 0, 0, 0.0001))
      );
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5) 0,
        rgba(0, 0, 0, 0.0001) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
      background-repeat: repeat-x;
      left: 0;
    }
    .el-carousel__arrow--right {
      right: 0;
      background-image: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0.0001) 0,
        rgba(0, 0, 0, 0.5) 100%
      );
      background-image: -o-linear-gradient(
        left,
        rgba(0, 0, 0, 0.0001) 0,
        rgba(0, 0, 0, 0.5) 100%
      );
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(0, 0, 0, 0.0001)),
        to(rgba(0, 0, 0, 0.5))
      );
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.0001) 0,
        rgba(0, 0, 0, 0.5) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
      background-repeat: repeat-x;
    }
  }
  .footerSweper {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    font-size: 19px;
    color: #fff;
    padding: 10px;
  }
}
.zone-con {
  padding-top: 10px;
  cursor: pointer;
  .zon-item {
    border-bottom: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
  }
}
.zone-1 {
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}
.zone-2 {
  border-bottom: 1px solid #eaeaea;
}
.zone-3 {
  border-right: 1px solid #eaeaea;
}
.zone {
  text-align: center;
  padding: 20px 0;
  color: #202020;
  font-size: 15px;
}
.box-con {
  padding: 15px 0;
}
.introduce-con {
  height: 132px;
  margin: 10px 0;
  box-shadow: 2px 2px 2px #888;

  img {
    width: 100%;
    height: 134px;
  }
  a {
    position: relative;
    bottom: 34px;
    font-size: 16px;
    z-index: 1100;
    color: #fff;
    padding: 10px;
    background-image: linear-gradient(360deg, transparent, #0252aa);
    background: linear-gradient(to left, transparent, #0252aa);
  }
}
.introduce-con a:hover,
a:active {
  color: #ddd;
}
@media screen and (max-width: 1200px) {
  .introduce-con {
    height: 112px;
    img {
      height: 112px;
    }
    a {
      bottom: 31px;
      font-size: 12px;
    }
  }
  .box-con {
    padding-bottom: 0;
    padding-top: 0;
  }
  .NewsBigimg .footerSweper{
    bottom: 15px;
  }
  .NewsBigimg .footerNews .el-carousel__arrow{
    height: 340px;
  }
  .NewsBigimg img{
    height: 340px;
  }
}
.improtant {
  display: block;
  position: relative;
  border-bottom: 1px dashed #f5f5f5;
  cursor: pointer;
  .improtant_title {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
    font-size: 14px;
    color: #666;
  }
  .big_title {
    font-size: 20px;
    color: #004da1;
 text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* 这里是超出几行省略 */
    overflow: hidden;
  }
  .time {
    text-align: right;
    margin: 3px 0;
    font-size: 15px;
    color: #bababa;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.title00{
      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.business_item{
  padding-right: 0 !important;
}
</style>
