<template>
  <div>
    <el-row class="top_logo" type="flex" align="middle">
      <el-col :xs="9" :lg="8" :xl="8">
        <img src="../assets/img/LOGO.png" />
      </el-col>
      <el-col :xs="5" :lg="5" :xl="5">
        <span>客户端下载</span>
      </el-col>
    </el-row>
    <el-row class="banner_box">
      <el-col :xs="24" :lg="24" class="banner_img">
        <img src="../assets/img/75.png" class="banner" />
      </el-col>
      <el-col class="box_item" :xs="12" :lg="12" :md='12' :sm="12">
        <el-row>
          
          <el-col :xs="4" :lg="4" :md="4" :sm='4'>
            <div @click="AnchorClick('#PC')">
            <el-row type="flex" align="middle" class="item_box">
              <img src="../assets/img/80.png" />
              <span>PC</span>
            </el-row>
            </div>
          </el-col>
          <el-col :xs="4" :lg="4" :md="4" :sm='4'>
             <div @click="AnchorClick('#Android')">
            <el-row type="flex" align="middle" class="item_box">
              <img src="../assets/img/81.png" />
              <span>Android</span>
            </el-row>
             </div>
          </el-col>
          <el-col :xs="4" :lg="4" :md="4" :sm='4'>
            <div @click="AnchorClick('#IOS')">
            <el-row type="flex" align="middle" class="item_box">
              <img src="../assets/img/82.png" />
              <span>IOS</span>
            </el-row>
            </div>
          </el-col>
          <el-col :xs="4" :lg="4" :md="4" :sm='4'>
            <div @click="AnchorClick('#WX')">
            <el-row type="flex" align="middle" class="item_box"  >
              <img src="../assets/img/83.png" />
              <span>微信版</span>
            </el-row>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row id="PC" class="banner_box">
      <el-col :xs="24" :lg="24" class="banner_img">
        <img src="../assets/img/76.png" class="banner" />
      </el-col>
      <el-col :xs="14" :lg="14" :md="14" :sm="14" class="Dow_item">
        <img src="../assets/img/80.png" class="icon" />
        <el-row class="DowItem_title">
          <el-col class="item_title">
            <span>应收账款债权流转管理系统</span>
            <div>PC版</div>
          </el-col>
          <el-col class="item_con">
            <span>无需下载</span>
            <span>输入www.zhongzhaitong.com</span>
            <span>即可进入中债通官网</span>
          </el-col>
          
        </el-row>
      </el-col>
    </el-row>
    <el-row id="Android" class="banner_box">
      <el-col :xs="24" :lg="24" :md="14" :sm="14" class="banner_img">
        <img src="../assets/img/77.png" class="banner" />
      </el-col>
      <el-col :xs="14" :lg="14" :md="14" :sm="14" class="Dow_item">
        <img src="../assets/img/81.png" class="icon" />
        <el-row class="DowItem_title">
          <el-col class="item_title">
            <span>应收账款债权流转管理系统</span>
            <div>Android版</div>
          </el-col>
         <el-row type="flex" align="middle" >
             <el-col :xs="18" :lg="18">
                 <el-col class="item_con">
            <span>大小：31.60MB</span>
            <span>版本：v5.5.9</span>
            <span>支持系统：Android 2.2及以上版本</span>
          </el-col>
          <el-col>
              <div class="Up_btn">立即下载</div>
          </el-col>
             </el-col>
              <el-col :xs="4" :lg="4">
              <img src="../assets/img/84.png">
          </el-col>
         </el-row> 
          
        </el-row>
      </el-col>
    </el-row>
    <el-row id="IOS" class="banner_box">
      <el-col :xs="24" :lg="24" class="banner_img">
        <img src="../assets/img/78.png" class="banner"  />
      </el-col>
      <el-col :xs="14" :lg="14" :md="14" :sm="14" class="Dow_item">
        <img src="../assets/img/82.png" class="icon"/>
        <el-row class="DowItem_title">
          <el-col class="item_title">
            <span>应收账款债权流转管理系统</span>
            <div>IOS版</div>
          </el-col>
         <el-row type="flex" align="middle">
          <el-col :xs="18" :lg="18">
              <el-col class="item_con" >
            <span>大小：9.30MB</span>
            <span>版本：v5.0.1</span>
            <span>支持系统：IOS 5.0及以上版本</span>
          </el-col>
           <el-col>
              <div class="Up_btn">立即下载</div>
          </el-col>
        </el-col>    
         <el-col :xs="4" :lg="4">
              <img src="../assets/img/84.png">
          </el-col>
        </el-row> 
          
        </el-row>
      </el-col>
    </el-row>
    <el-row id="WX" class="banner_box">
      <el-col :xs="24" :lg="24" class="banner_img">
        <img src="../assets/img/79.png" class="banner" />
      </el-col>
      <el-col :xs="14" :lg="14" :md="14" :sm="14" class="Dow_item">
        <img src="../assets/img/83.png" class="icon" />
        <el-row class="DowItem_title">
          <el-col class="item_title">
            <span>应收账款债权流转管理系统</span>
            <div>微信版</div>
          </el-col>
         <el-row type="flex" align="middle">
          <el-col class="item_con" :xs="18" :lg="18">
            <span>无需下载</span>
            <span>通过微信关注即可，操作步骤：</span>
            <span> 1.打开微信的扫一扫，扫描二维码。</span>
             <span>2.出现中债通官微服务号，点关注。</span>
              <span>3.进入中债通官微服务号即可使用。</span>
          </el-col>
          <el-col :xs="4" :lg="4">
              <img src="../assets/img/84.png">
          </el-col>
         </el-row>   
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data(){
    return{}
  },
  mounted(){
    // console.log(this.$route.params.id)
    var FoteerId=this.$route.params.id
    if(FoteerId!=undefined){
      document.querySelector(FoteerId).scrollIntoView(true);
    } 
  },
  methods:{
    AnchorClick(id){
      document.querySelector(id).scrollIntoView(true);
    }
  }
};
</script>
<style lang="less" scoped>
.top_logo {
  width: 1200px;
  margin: 0 auto;
  padding: 31px 0 23px 0;
  .el-col {
    span {
      font-size: 40px;
      // margin-left: -20px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.banner_img {
  
        width: 100%;
    // height: 336px;
    text-align: center;
    min-width: 1100px;
    overflow: hidden;
    .banner{
        margin-left: -50%;
    margin-right: -50%;
    vertical-align: middle;
    }
    // object-fit: contain;
  
}
.banner_box {
  position: relative;
  .box_item {
    position: absolute;
    bottom: -20px;
    right: 0;
    transform: translate(0%, -50%);
    padding: 0 20px;
    line-height: 42px;
    text-align: center;
    .item_box {
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
      span {
        color: #164a96;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  @media (aspect-ratio:1200/334){
    .banner{
            width: 1200px;
            aspect-ratio: auto 1600 / 334;
            height: 334px;
          }
      
}
  .Dow_item {
    position: absolute;
    top: 35%;
    right: 0;
    transform: translate(0, -30%);
    display: flex;
    padding: 5px 0;
    .icon {
      width: 25px;
      height: 25px;
      padding: 3px 0;
    }
    .item_title {
      display: flex;
      align-items: center;
      font-size: 24px;
      div {
        color: #164a96;
      }
    }
    .DowItem_title {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding-left:26px;    
      width: 100%;
      max-width: 721px;
      .item_con {
        color: #888888;
        font-size: 16px;
        padding-top: 15px;
        display: flex;
        flex-flow: column;
        span {
          padding: 5px 0;
        }
      }
    }
    .Up_btn{
    background: #164A96;
    color: #fff;
    padding: 10px 0;
    width: 167px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-top: 60px;
    }
  }
}
</style>