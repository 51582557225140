<template>
<div>
 <el-row>
        <el-col>
            <img src="../assets/img/34.png" style="width:100%">
        </el-col>
    </el-row>
    <el-row type="flex" justify="center" >
      <el-col class="con-title">
        <img src="../assets/img/left.png">
        <span>债权质押流程</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row type="flex" class="process2" align="middle">
      <el-col :xs="3" :md="3" :lg="3" :xl="4"  class="left-box">
        <el-col :span="8">
           <router-link to="/Download"> 
          <div class="item-box">
           <div class="img"></div>
            <span>APP下载</span>
          </div>
           </router-link>
        </el-col>
        <el-col :span="8">
          
          <div class="item-box1" @click="goDownload('#WX')">
            <div class="img1"></div>
            <span>微信小程序</span>
          </div>
           
        </el-col>
        <el-col :span="8">
          <div class="item-box2" @click="call_me('#call')">
            <div class="img2"></div>
            <span>联系我们</span>
          </div>
        </el-col>
      </el-col>
        <el-col :lg="21" :xl="21" class="process">
            <img src="../assets/img/35.png" >
        </el-col>
    </el-row>
    <el-row class="pledge-concept">
        <el-col >
             <img src="../assets/img/36.png" style="width:100%">
            <div class="service-title">
          <img src="../assets/img/left-1.png">
        <span>债权质押概念</span>
        <img src="../assets/img/right-1.png">
        </div>
        <el-row class="concept-con" type="flex" justify="center">
          <el-col :lg="24" :xl="24" >
            <span>
              债权质押，是由持有中债通和当地应收账款支付公司认证的应收账款债权的用户，以所持资产为质押物，向当地金融机构申请贷款的流程。中债通和当地应收账款支付公司认证的应收账款债权，是具有当地国有信用背书和流动性的质押品，已经由当地金融机构认证并被当地金融机构高度认可，客户可随时自由的以此申请贷款。债权质押功能有效盘活企业和个人现有债权，增加政、企、银优质资产，增加中小企业融资投放渠道。

            </span>
          </el-col>
        </el-row>
        </el-col>
    </el-row>
    <el-row type="flex" class="rules">
     <el-col class="rules-title">
        <img src="../assets/img/left.png">
        <span>债权质押规则</span>
        <img src="../assets/img/right.png">
      </el-col>
    </el-row>
    <el-row class="pledge-rules" type="flex" >
        <el-col  :md="16" :lg="10" :xl="10">
            <img src="../assets/img/37.png" style="width: 100%;">
        </el-col>
        <el-col  :lg="11" :xl="11" class="pledge-Con">
            <el-col>1、客户在提出债权质押贷款申请流程</el-col>
            <el-col>2、银行审核客户贷款资料和质押物有效性</el-col>
            <el-col>3、银行同意债权质押</el-col>
            <el-col>4、中债通冻结质押债权</el-col>
            <el-col>5、银行放款</el-col>
            <el-col>6、客户到期还款、债权解冻恢复正常</el-col>
        </el-col>
    </el-row>
</div>
</template>
<script>
export default {
    data(){
      return{}
    },
    methods:{
      call_me(id){
        this.$router.push({name:'About',path:'/About',params:{
           id:id
        }})
     },
     goDownload(id){
        if(this.$route.path=='/Download'){
           document.querySelector(id).scrollIntoView(true);
        }else{
           this.$router.push({name:'Download',path:'/Download',params:{
           id:id
        }})
        }
      }
    }
}
</script>
<style lang="less" scoped>
.con-title {
 display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
        width: 1200px;
  span {
    justify-content: center;
    display: flex;
    color: #004da1;
    font-size: 26px;
  }
  img{
     padding: 0 40px;
   // margin: 0 40px;
  }
}
.process{
    text-align: center;
}
.pledge-concept{
    margin: 30px 0;
   .service-title {
    position: absolute;
    left: 50%;
    top: 10px;
    z-index: 1100;
    transform: translate(-50%, 20px);
    display: flex;
    align-items: center;
    justify-content: center;
        width: 1200px;
    span {
      font-size: 26px;
      color: #fff;
    }
    img{
      padding:0 40px;
    }
  }
  .concept-con {
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -30%);
    position: absolute;
    z-index: 1100;
    line-height: 25px;
    width: 1200px;
    font-size: 17px;
    line-height: 1.8;
    text-indent: 2rem;
    text-align: justify;
  }
}
.pledge-rules{
    display: flex;
    align-items: center;
        width: 1200px;
    padding-bottom: 30px;
    margin: 0 auto;
    img{
        width: 100%;
    }
    .pledge-Con{
        margin: 30px;
        .el-col{
            padding: 10px 0;
            color: #202020;
        }
    }
}
.process2{
  width: 1200px;
  margin: 0 auto;
.left-box {
  display: flex;
  flex-flow: column;

  .item-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 .img {
    background: url("../assets/img/15.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
    color: #333;
  }
}
}
.item-box:hover{
    .img{
    background: url("../assets/img/15-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
.left-box {
  display: flex;
  flex-flow: column;

  .item-box1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 .img1 {
    background: url("../assets/img/13.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
    color: #333;
  }
}
.item-box1:hover{
    .img1{
    background: url("../assets/img/13-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
.left-box {
  display: flex;
  flex-flow: column;

  .item-box2 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #EEEEEF;
    margin: 5px 0;
    cursor: pointer;
  }
 .img2 {
    background: url("../assets/img/14.png") 30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
    // width: 30px;
    // height: 30px;
  }
  span{
    font-size: 14px;
    padding: 5px 0;
    color: #333;
  }
}
.item-box2:hover{
    .img2{
    background: url("../assets/img/14-1.png")30px 20px no-repeat;
    width: 100px;
    background-size: 40px 40px;
    height: 100px;
  }
  span{
    color: #004da1;
  }
 }
.rules{
      width: 1200px;
    margin: 0 auto;
    // padding: 20px 0;
    .rules-title{
        color: #004da1;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
        padding-bottom: 20px;
img{
  padding: 0 40px;
}
    }
}
</style>