<template>
  <div>
    <el-row>
      <el-col>
        <img src="../assets/img/52.png" style="width:100%" />
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 60px;">
      <el-col :xs="5" :lg="5">
        <div class="tabs">
          <el-col :xs="15" :lg="15" v-for="(tab,index) in tabsName" class="tabBox" :key="tab.id">
            <div class="tab-link" @click="tabsSwitch(index)" v-bind:class="{active:tab.isActive}">
              <img v-if="tab.isActive==false" :src="tab.img" />
              <img v-else :src="tab.img2" />
              <div>{{tab.name}}</div>
            </div>
          </el-col>
        </div>
      </el-col>
      <el-col :xs="17" :lg="17">
        <div class="tab-card" style="display: block;">
          <el-col class="card-item">
            <span class="card-title">Q : 什么是应收账款债权？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A： 应收账款是指权利人因提供一定的货物、服务或设施而获得的要求义务人付款的权利，
              包括现有的和未来的金钱债权及其产生的收益，但不包括因票据或其他有价证券而产生的付款请求权。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 什么是应收账款债权凭证（合同）流转业务？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A：应收账款债权凭证（合同）流转业务也称为应收账款债权流转业务，
              是指拥有应收账款的各类经济主体，经应收账款债权管理服务机构审查确认，
              通过交纳一定比例保证金，用法律上拥有处分权的各类资产或权益抵质押做保障
              ，由银行、保险、金融资产管理公司、非金融资产管理公司、融资担保公司、小额贷款公司等机构等参与增信，
              将各类经济主体之间的静态应收账款转变成为应收账款债权凭证（合同）记载的可流转应收账款，
              通过转让、融资、融货、抵付（销）、交易、质押等多种方式，实现债权盘活和债务化解的业务。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 什么是应收账款债权管理服务机构？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A：应收账款债权管理服务机构是指经地方政府或国企独资组建的应收账款债权管理公司、人民法院、仲裁委员会以及依法成立的商会调解、
              人民调解等各类调解组织，与国家相关部委认可的具有专业应收账款债权凭证（合同）流转业务操作经验和资质的单位合作，
              应用国家科技支撑计划“基于债权流转的新一代电子支付服务体系研发及应用示范”项目技术和模式，会同银行、保险或融资担保公司等机构，
              对应收账款的真实性、关联性、合法性进行审查，对应收账款债权流转业务申请人的权利义务进行确认，
              为符合条件的申请人通过应收账款债权凭证（合同）流转系统开具应收账款债权凭证（合同），开展登记、公示等服务，
              并协同上述机构为应收账款债权流转提供增信、质押登记、交易、融资、偿付、追偿、资金托管等服务的第三方服务机构。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 什么是应收账款债权凭证（合同）？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A：应收账款债权凭证（合同）是指应收账款债权管理服务机构依据客户申请，
              经审查确认，通过应收账款债权凭证（合同）流转系统开具的纸质或电子形式的摘要记载应收账款债权及流转等有关信息的债权凭证。
              应收账款债权凭证（合同）不是有价证券。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 什么是债权账户？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A：债权账户是指企业及个人等各类经济主体通过应收账款债权管理服务机构，在应收账款债权凭证（合同）流转系统开设的，
              用于应收账款债权凭证（合同）流转登记的专用账户。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 什么是应收账款债权凭证（合同）流转系统？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A：应收账款债权凭证（合同）流转系统是国家科技支撑计划“基于债权流转的新一代电子支付服务体系研发及应用示范”项目所属的系统统称，科技部支持全国推广应用。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 是否需要实名认证？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A：债权账户全部实名制，线上、线下通过国有独资应收账款债权管理公司、
              仲裁委员会或人民法院支持的调解组织办理债权账户注册均需实名认证。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q :为什么需要绑定银行卡？</span>
          </el-col>
          <el-col>
            <span class="card-con">A：绑定银行卡（银行结算账号）是用于接收所持有的凭证（合同）记载的应收账款债权到期对应资金。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 注册成功后，有哪些渠道可以登录？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A：注册成功后，客户可以凭客户号/手机号和密码通过中债通网、中债通手机APP以及PC版客户端等渠道登录，客户也可以就近到开展业务的应收账款债权管理公司、仲裁委员会或调解组织等营业网点现场办理。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 为什么要邀请好友加入？</span>
          </el-col>
          <el-col>
            <span class="card-con">A：成为好友后，便于一对一快速转（受）让债权等。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 为什么要建立好友群？</span>
          </el-col>
          <el-col>
            <span class="card-con">A：您可以定向将债权转（受）让信息、发放债权红包、产品信息、优惠促销信息时等发布到群。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 如何将我持有的债权转让给他人？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A : 可以选择到柜台办理或网上自助服务：
              柜台办理：债权转让双方可就近到开展应收账款债权凭证（合同）流转业务的应收账款债权管理公司、仲裁委员会或调解组织等营业网点现场签署转让合同，办理债权转让登记等相关手续。
              网上办理：债权转让双方可通过中债通手机客户端、电脑客户端以及中债通网等网上服务渠道签署债权转让电子合同，自助办理债权转让登记等相关手续。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 债权转让数额与转让价款？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A : 1.债权转让数额是指债权出让人向债权受让人所转让的具体债权数额，简称为转让数额。
              2.债权转让价款是指债权受让人受让债权出让人所转让的债权，应向债权出让人支付的债权转让对价，如变现、抵付债务金额，简称转让价款或转让对价。
              例如：债权持有人张三将持有数额为10000元的债权按9折转让给李四，获得9000元现金。
              此时：转让数额为10000元，转让价款（变现金额）为9000元。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 如何用债权抵付债务？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A : 抵付债务是指债权持有人通过债权转让方式抵付所欠的债务。
              例如：张三持有1000元的债权，同时欠李四500元的债务，双方同意由张三向李四转让550元的债权抵付500元的欠款。 此时：债权转让数额为550元，转让对价为500元。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 怎么用债权购物消费？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A : 债权购物消费是指债权持有人在商户处购物消费时，通过债权转让方式应付商户的购物消费款等款项。
              例如：张三在商户处消费100元，商户同意接受105元的债权抵付该消费款。 此时：债权转让数额为105元，转让对价（抵付金额）为100元。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 如何用债权变现？</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A : 债权变现是指债权持有人将债权转让给第三方以获得现金。
              例如：张三持有10000元的债权，期限为1年，按85折转让给第三方获得8500元现金。 此时：债权转让数额为10000元，转让对价（变现金额）为8500元。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 债权应计利息怎么算</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A : 债权应计利息是指对于确权付息型可流转应收账款债权（债务人承诺付息的），根据具体债权持有数额及年利率计算自债权初始登记日起至今日止的应得利息（单利）。
              对于如承诺支付型、承诺回购型、承诺抵付型等非确权付息型可流转应收账款债权无利息，此时应计利息为0。
            </span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 债权转让利息怎么算</span>
          </el-col>
          <el-col>
            <span class="card-con">
              A : 债权转让利息是指对于确权付息型可流转应收账款债权（债务人承诺付息的）债权转让时，随同债权一并转让的应计利息，该利息根据具体债权转让数额及年利率计算（单利），自债权初始登记日起算至债权转让之日止
              对于如承诺支付型、承诺回购型、承诺抵付型等非确权付息型可流转应收账款债权无利息，此时转让利息为0。
            </span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 什么是债权融货？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A：债权融货是指债权持有人用凭证（合同）记载的债权向上游供应商抵付购货款、服务款等各项应付款，通过债权抵付购买产品或服务，不用或少用现金。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 如何进行融货？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A：购销双方达成合意，一致同意通过债权转让抵付货款的，双方可至开展业务的应收账款债权管理公司、仲裁委员会或调解组织等营业网点现场签署债权转让合同，办理债权转让相关手续，或通过网上自助办理，平台自身并不涉足购销合同的具体执行。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 什么是特约商户？</span>
          </el-col>
          <el-col>
            <span class="card-con">A：特约商户是指愿意接受应收账款债权凭证（合同）所记载的应收账款债权进行抵付的各行业优质商家。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 是否支持扫码转（受）让债权？</span>
          </el-col>
          <el-col>
            <span class="card-con">A : 下载中债通手机APP，即可通过手机扫描二维码快速办理债权转让、受让确认等业务。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 如何制作债权账户二维码？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A : 您可以登录中债通网或PC客户端，下载二维码电子文档（PDF），打印后放置于柜台，以便于客户随时扫码转让；面对面转让时，也可随时出示中债通手机APP中对应的二维码。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 如何更便捷转（受）让小额债权？</span>
          </el-col>
          <el-col>
            <span class="card-con">A : 您可以通过中债通手机APP开启小额免密转让和受让功能，开启后，一定金额内的债权转让、受让无需密码验证，操作更快捷。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 什么是闭环抵销？</span>
          </el-col>
          <el-col>
            <span class="card-con">A : 债权债务闭环抵销，是指多个经济主体间互负债权债务，通过大数据发现闭环关系，实现各主体间的债权债务等额抵销。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 哪些债权债务可以参与闭环抵销？</span>
          </el-col>
          <el-col>
            <span class="card-con">A : 各类经济主体间的应收、应付均可以参与抵销，包括各类不良的应收账款债权，如法院已判决但难以执行的债权。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 平台如何发布转让信息？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A : 平台提供转让信息发布功能，债权持有人有转让需求时，可通过系统实时向全系统公开或定向发布转让信息，受让人可选择债权提交受让意向，达成意向后，双方可至对应的债权管理公司现场办理相关转让手续，也可自行通过网银转账、微信支付等方式完成价款支付，并通过中债通APP、中债通网等渠道提供的债权转让功能进行债权转让登记。</span>
          </el-col>
          <el-col class="card-item">
            <span class="card-title">Q : 什么是担保受让人？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A : 担保受让人是指为凭证（合同）记载的债权提供转（受）让服务，并将债权等额资金存入指定监管账户的投资机构、资产管理公司等，对于有担保受让人的凭证（合同），债权持有人可随时向对应的担保受让人按照不同期限不同折价率转让债权获得资金。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 如何办理应收账款质押融资？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A : 符合银行授信条件的债权持有人，可以根据应收账款债权凭证（合同）记载的贷款服务银行信息，线下自行向合作银行申请应收账款质押贷款（融资），具体依照各合作银行制定的配套业务管理办法办理。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <el-col class="card-item">
            <span class="card-title">Q : 持有的债权到期如何实现？</span>
          </el-col>
          <el-col>
            <span
              class="card-con"
            >A：每笔债权到期的实现方式具体根据每笔债权的不同情况及増信措施确定，如产业链龙头核心企业承诺支付型，到期由承诺支付的债务人向最终持有人付款；承诺回购型，到期由承诺回购的初始债权人向最终持有人付款；有担保受让人的，债权持有人还可随时向担保受让人转让实现债权；有第三方担保或保险的，由第三方担保或保险公司承担担保或保险责任等。</span>
          </el-col>
        </div>
        <div class="tab-card" style="display: none;">
          <p>为确保用户的信息安全，保护相关方的权利不受侵害，共同维护中债通网站（以下简称“本网站”）资源，北京中债通有限公司特制定以下声明（以下简称“本声明”）：</p>
          <p class="text_title">一、知识产权</p>

          <p>本网站所有资料（包括但不限于以下内容：本网站的整体结构、网页设计、文本、图形及图像、域名、商标、LOGO、视像及声音内容、软件或文档、创意等）均受中国有关商标、著作权和专利权等知识产权的法律保护。未经特殊说明，上述内容之商标权、著作权和专利权等知识产权归属北京中债通有限公司及/或本网站的内容/信息提供者所有。</p>
          <p class="text_title">二、网站使用</p>
          <p>除非本声明另有规定，用户在使用本网站之内容时，应遵守下列约定：</p>
          <p>1.未经北京中债通有限公司书面许可，不得复制、留存、编辑、修改、组合、镜像，转发、出版、演示、抄袭本网站之任何内容或将本网站之任何内容用于任何未经北京中债通有限公司允许的商业目的。</p>
          <p>2.摘录或转载本网站原创稿件时，须经北京中债通有限公司书面许可并在明显位置注名信息来源：北京中债通有限公司官网，摘录或转载应确保反映原文的真实含义。对擅自篡改或歪曲原文真实含义的行为，以及未经北京中债通有限公司书面许可擅自摘录或转载本网站原创稿件且不注明信息来源的行为，北京中债通有限公司保留采取进一步法律措施的权利。如需摘录或转载本网站包含的由其它组织、机构或个人提供的信息内容时，请与相关权利人取得联系，与之相关的任何事务以及法律责任均与本网站和北京中债通有限公司无关。</p>
          <p>3.任何时间，任何情况下，北京中债通有限公司均有权拒绝任何用户访问、使用本网站。如果北京中债通有限公司确定用户行为违法或有损本网站和北京中债通有限公司的利益，则北京中债通有限公司将保留追究相关责任企业或个人的权利。</p>
          <p class="text_title">三、链接</p>
          <p>本网站的运营网址是【www.zhongzhaitong.com】，对于其他声称是北京中债通有限公司的网站，北京中债通有限公司不承担任何法律责任。</p>
          <p>未经北京中债通有限公司书面同意，其它网站不得链接本网站，对于任何第三方未经同意建立的与本网站的链接，北京中债通有限公司均不作任何保证、承诺，不承担任何法律责任。</p>
          <p>本网站如保留有与第三方网站网址的链接，访问这些链接将由用户自行决定，北京中债通有限公司并不保证这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性。对因通过本网站访问其他链接网站而发生的任何损害，北京中债通有限公司不承担任何法律责任。</p>
          <p class="text_title">四、网址安全规则</p>
          <p>禁止本网站用户破坏或企图破坏本网站的安全规则，破坏或企图破坏的行为包括但不限于:</p>
          <p>(一)接触未经许可的数据或进入未经许可的服务器或帐户;</p>
          <p>(二)没有得到许可，企图探查，扫描或测试系统或网络的弱点，或者破坏安全措施；</p>
          <p>(三)企图干涉对用户及网络的服务，包括但不限于通过超载，"邮件炸弹"或"摧毁"等手段;</p>
          <p>(四)发送促销，产品广告及服务的E-mail；</p>
          <p>(五)伪造TCP/IP数据包名称或部分名称，破坏系统或网络可能导致犯罪,北京中债通有限公司将予以追究责任。</p>
          <p>(六)未经授权盗用本网站内容以欺瞒用户,以达到其商业盈利目的,北京中债通有限公司将予以追究责任。</p>
          <p class="text_title">五、免责</p>
          <p>北京中债通有限公司不保证本网站内容的准确性、充分性、可靠性或完整性。本网站提供的内容只作为参考，不构成任何财务建议或其它建议，不构成任何要约或承诺，北京中债通有限公司不就用户的任何损害承担责任（包括但不限于基于任何原因发生的利润的损失、数据丢失、间接损失、附带损失或业务中断而造成的损害），无论这些损害是否由于使用或不能使用本网站、与本网站链接的任何网站或者任何这类网站中包含的信息所引起，也不管它们是否有保证、合同、侵权行为或任何其它法律根据以及事前已得到这类损害可能发生的忠告。如果由于使用本网站的信息而导致需要对设备或数据进行维护、修理或纠正，用户须自行承担由此而造成的所有费用。互联网传输可能会受到干扰，出现中断、延迟或数据错误等现象。对于非北京中债通有限公司所能控制的情况（包括但不限于通讯设施故障）可能引致的数据及交易的不准确性或不及时性，北京中债通有限公司不承担任何责任。</p>
          <p class="text_title">六、适用法律与管辖</p>
          <p>任何有关本网站和本声明的争议、纠纷，均适用中华人民共和国法律。任何有关本网站和本声明的争议，应由北京市有管辖权的人民法院管辖。</p>
          <p>本声明的解释权及对本网站使用的解释权归北京中债通有限公司所有，北京中债通有限公司保留随时更正、修改、更新本声明的权利。如果您对本声明在使用中的问题有任何意见和建议请和我们联系。</p>
          <p class="text_title">七、我们如何收集和使用您的个人信息</p>
          <p>7.1 您主动向我们提供相关信息，包括：</p>
          <p>7.1.1 当您注册债权账号时，如果您是个人用户，您填写及/或提交的信息，包括您的手机号码、债权账号登录密码、开户网点等；如果您是企业用户，您填写及/或提交的信息，包括企业名称、注册地址、手机号码、债权账号登录密码、开户网点等。</p>
          <p>7.1.2 当您进行债权账号实名认证时，如果您是个人用户，您填写及/或提交的信息，包括您的姓名、身份证件信息、地址、开户网点、债权账号转受让密码、用于收款银行账户信息等；如果您是企业用户，您填写及/或提交的信息，包括企业名称、统一社会信用代码证、注册地址、法人代表信息、联系人信息、联系电话、开户网点、企业开户申请表、债权账号转受让密码、用于收款银行账户信息等。</p>
          <p>7.1.3 如果您是特约商户，当您开通应收账款债权流转平台特约商户时，您填写及/或提交的信息，包括您的企业相关信息（名称、地址、电话/手机、所属行业）、企业介绍、产品/服务信息以及优惠信息等。</p>
          <p>7.1.4 当您转让债权时，为了完成您所选择的服务或达成的交易，您填写及/或提交的信息，包括所转让的债权信息（包括债权代码、转让数额、转让价格）、受让人信息（包括受让人名称、客户号）、留言/转让用途等。</p>
          <p>7.1.5 当您发布债权转让信息时，为了完成您所选择的服务或达成的交易，您填写及/或提交的信息，包括拟转让的债权信息（包括债权代码、转让数额、转让价格）、截止日期、手机号码等。</p>
          <p>7.1.6 当您申报债权债务信息时，为了完成您所选择的服务或达成的交易，您填写及/或提交的信息，包括债权人信息、债权数额、债权依据、债务人信息等。</p>
          <p>7.1.7 为使服务更便捷，从而提升您的服务体验，我们的以下功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用我们的相关服务。</p>
          <p>7.2 您可选择是否授权我们收集和使用您的个人信息的情形</p>
          <p>
            为提升您的服务体验，我们的以下扩展功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用应收账款债权流转平台的基本功能。这些扩展功能包括：
            基于位置信息的个性化推荐功能：当您浏览特约商户信息时，我们会收集您的移动设备位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您所在区域的特约商户信息。比如向您推荐离您最近的可选特约商户门店等信息。
            基于通讯录信息的扩展功能：当您选择从手机通讯录添加好友时，我们会收集您的通讯录信息来判断通讯录中的手机号码是否已添加为好友，以自动标记是否可以添加。
            基于摄像头（相机）的扩展功能：您可以使用这个扩展功能完成视频拍摄、拍照以及扫码的功能，用于扫描二维码转受让债权、上传自定义头像或相关资料等。
            基于图片上传的扩展功能：我们访问您的相册可以帮助您实现您手机中的照片、图片或视频的取用和上传，便于您更换头像或上传相关资料等。
          </p>
          <p>上述扩展功能可能需要您在您的移动设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）以及通讯录的访问权限，以实现这些功能所涉及的信息的收集和使用。您可以决定将这些权限随时开启或关闭（我们会指引您在您的设备系统中完成设置）。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</p>
          <p class="text_title">八、我们从第三方获得您个人信息的情形</p>
          <p>我们可能从第三方获取您授权共享的账户信息，并在您同意本隐私政策后将您的第三方账户与您的债权账户绑定，使您可以通过第三方账户直接登录并使用我们的服务与/或产品。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</p>
          <p class="text_title">九、您个人信息使用的规则</p>
          <p>
            我们会根据本隐私政策的约定并为实现我们的服务与/或产品功能对所收集的个人信息进行使用。
            在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
            我们会对我们的服务与/或产品使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的服务与/或产品的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
            当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
            当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。
          </p>
          <p class="text_title">十、我们如何保护和保存您的个人信息</p>
          <p>为保障您的信息安全，汇浦努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或丢失，包括但不限于HTTPS协议进行传输加密、SSL、信息加密存储、数据中心的访问控制。汇浦对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施，进行安全和隐私保护培训。汇浦会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，汇浦将尽力做到使您的信息不被泄漏、毁损或丢失。</p>
        </div>
        <div class="tab-card" style="display: none;"></div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabsName: [
        // {
        //   name: "软件下载",
        //   img: require("../assets/img/53.png"),
        //   img2: require("../assets/img/54.png"),
        //   isActive: true
        // },
        {
          name: "名词解释",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: true
        },
        {
          name: "注册与认证",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "好友与群",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "债权转让",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "债权融货",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "扫码转受让",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "闭环抵消",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "转让信息发布",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "质押融资",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "到期回购",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "隐私保护",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        },
        {
          name: "客户端下载",
          img: require("../assets/img/53.png"),
          img2: require("../assets/img/54.png"),
          isActive: false
        }
      ],
      img2: require("../assets/img/54.png"),
      img3: require("../assets/img/53.png")
    };
  },
  mounted() {},
  methods: {
    // 选项卡事件
    tabsSwitch: function(tabIndex) {
      if(tabIndex==11){
        this.$router.push('/Download')
      }
      var tabCardCollection = document.querySelectorAll(".tab-card"),
        len = tabCardCollection.length;
      for (var i = 0; i < len; i++) {
        tabCardCollection[i].style.display = "none";
        this.tabsName[i].isActive = false;
        this.tabsName[tabIndex].img = this.img3;
      }
      this.tabsName[tabIndex].isActive = true;
      tabCardCollection[tabIndex].style.display = "block";
    }
  }
};
</script>
<style lang="less" scoped>
.tabs {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  margin: 20px 0;
   .tabBox{
      box-shadow: 0 10px 10px 1px #ddd;
    }
  .tab-link {
    padding: 15px 5px;
    text-align: center;
    background: #f5f5f5;
    color: #515151;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    white-space: nowrap;
    border-bottom: 1px solid #fff;
    img {
      margin-right: 20px;
      width: 20px;
      height: 20px;
    }
   
  }
  .active {
    background: #004da1;
    color: #fff;
  }
}
.tab-card {
  margin-left: 30px;
  background: #f6f7f8;
  margin-top: 20px;
  text-align: justify;
  padding-bottom: 50px;
  .card-item {
    padding: 20px 0;
    .card-title {
      color: #004da1;
      font-weight: 600;
      line-height: 20px;
    }
    .card-con {
      letter-spacing: 2px;
      line-height: 20px;
    }
  }
  .text_title {
    font-weight: 600;
  }
}
</style>