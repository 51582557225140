<template>
  <div id="app">
    <top-box></top-box>
    <!-- <search class="search"></search> -->
    <menu-list style="background: #fff;" v-show="!(path=='/PersonalLogin' || path=='/Download' || path=='/CollectLogin' || path=='/PayLogin' || path=='/FinancialLogin' || path=='/GovernmentLogin' || path=='/CooperationLogin' )  "></menu-list>
     
      <router-view style="min-width:1200px;" />

    <div class="footer-zone"><footer-box class="footer"  v-show="!(path=='/PersonalLogin' || path=='/CollectLogin'|| path=='/PayLogin' || path=='/GovernmentLogin'  || path=='/CooperationLogin' || path=='/FinancialLogin' )"></footer-box></div>
  </div>
</template>

<script>

// import Search from "./components/Head.vue";
import MenuList from "./components/MenuList.vue";
import FooterBox from "./components/Footer.vue";
import TopBox from "./components/Top.vue"
export default {
  name: 'app',
   components: {  MenuList, FooterBox,TopBox },
   data(){
     return{
       path:''
     }
   },
   mounted(){
      this.path = this.$route.path;
      
   },
   watch:{
      $route(to,from){
        this.path = to.path
      
      }
   }

}
</script>

<style lang="less" >
body{
  margin: 0;
  height: 100%;
}
#app{
  // min-width: 1300px;
  padding: 0;
  margin: 0;
  width: 100%;
  // background: #F6F7F8;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.footer-zone{
  position: relative;
  .footer{
    // position: absolute;
    bottom: 0;
  }
}
.search{
  background: #fff;
}
</style>

